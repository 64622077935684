import { put, takeEvery, select, call } from "redux-saga/effects";
import { failure } from "../actions";
import {
  AUTH,
  REPORT,
  AVATAR,
  DEFAULT_PARAMETERS,
  MESSAGES,
  PRIZE,
  APPCONFIG,
  WEBSOCKET,
  ANALYTICS,
  USERLIST,
  REFRESHDATA,
  EPISODELIST,
  SEASONLIST,
  NOTICEBOARD,
  MSG,
  BANNERS,
  GAMELIST,
  STACKLIST,
  QUESTIONLIST,
  COUNTS,
  WINNERLIST,
  SCHOOLLIST,
  FEEDBACK,
  PAYMENT,
  FAQLIST,
} from "../constants/constants";
import AppConfig from "../../configs/AppConfig";
import axios from "axios";
export const token = (state: any) => state.adminReducer.token;
export const userid = (state: any) => state.adminReducer.admin.id;
export const QuestionCount = (state: any) => state.adminReducer.TotalQuestion;

function* errorCheck(error: any) {
  console.log("error", error?.response?.data?.msg);
  yield put({
    type: MESSAGES.ERROR,
    error: error?.response?.data?.msg,
    status: error?.response?.status,
  });
}

export function* handleLogin(dataObj: any) {
  try {
    yield put({ type: AUTH.REMEMBER_ME, value: dataObj.data });
    let message = {
      action: "admin/adminSignin",
      data: {
        stage: AppConfig.stage,
        admin: true,
        username: dataObj.data.username.trim(),
        password: dataObj.data.password,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.LOGIN_SUCCESS, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFetchAnalytics(dataObj: any) {
  try {
    let message = {
      action: "admin/iqlAnalytics",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        gameid: dataObj?.data?.gameid ?? 7,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.DIGITOK_ANALYTICS, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFetchNoticeBoard() {
  try {
    let message = {
      action: "noticeList",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_NOTICEBOARD, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFetchBanners() {
  try {
    let message = {
      action: "bannersList",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_BANNERS, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleAllCounts() {
  try {
    yield put({ type: REFRESHDATA.MSG, value: "" });
    let message = {
      action: "admin/counts",
      data: {
        UserId: yield select(userid),
        authToken: yield select(token),
        condition: {},
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_COUNT, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleAddBanner(dataObj: any) {
  try {
    yield put({ type: REFRESHDATA.MSG, value: "" });
    let message = {
      action: "admin/createBanner",
      data: {
        stage: AppConfig.stage,
        authToken: yield select(token),
        banners: dataObj.data.path,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_BANNERSCREATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleAddNotice(dataObj: any) {
  try {
    yield put({ type: REFRESHDATA.MSG, value: "" });
    let message = {
      action: "admin/createNotice",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        noticeImageUrl: dataObj.data.path,
        noticeImageText: dataObj.data.imageText,
      },
    };

    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_NOTICECREATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFetchGameList(dataObj: any) {
  try {
    let message = {
      action: "gamesList",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        page: dataObj && dataObj.data && dataObj.data.page ? dataObj.data.page : 1,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_GAMELIST, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleCreateGame(dataObj: any) {
  try {
    let message = {
      action: "admin/createGame",
      data: {
        authToken: yield select(token),
        UserId: yield select(userid),
        stage: AppConfig.stage,
        ...dataObj.data,
      },
    };
    // dataObj.data.authToken = yield select(token);
    // dataObj.data.userid = yield select(userid);
    // dataObj.data.stage = AppConfig.stage;
    // dataObj.action = "admin/createGame";
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("new game", data);
    yield put({ type: MESSAGES.RESPONSE_GAMECREATE, value: data });
  } catch (error) {
    console.log("new game", error);
    yield errorCheck(error);
  }
}

export function* handleUpdateGame(dataObj: any) {
  try {
    let message = {
      action: "admin/gameUpdate",
      data: {
        authToken: yield select(token),
        id: dataObj.data.id,
        stage: AppConfig.stage,
        fields: dataObj.data,
      },
    };
    console.log("");
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_UPDATEGAME, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUpdateGamePosition(dataObj: any) {
  try {
    let message = {
      action: "admin/gamePositionUpdate",
      data: {
        authToken: yield select(token),
        id: dataObj.data.id,
        stage: AppConfig.stage,
        fields: dataObj.data,
      },
    };
    console.log("");
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_UPDATEGAME, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteGame(dataObj: any) {
  yield put({ type: GAMELIST.DELETE_GAME, value: dataObj });
  try {
    let message = {
      action: "admin/gameDelete",
      data: {
        authToken: yield select(token),
        gameId: dataObj.data,
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    //  yield put({ type: GAMELIST.DELETE_GAME, value: dataObj });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFetchSeasonList() {
  try {
    let message = {
      action: "admin/seasonsList",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_SEASONLIST, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleCreateSeason(dataObj: any) {
  try {
    dataObj.data.authToken = yield select(token);
    dataObj.data.userid = yield select(userid);
    dataObj.data.stage = AppConfig.stage;
    dataObj.action = "admin/createSeason";
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_SEASONCREATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUpdateSeason(dataObj: any) {
  try {
    yield put({ type: REFRESHDATA.MSG, value: "" });
    let mssg = {
      action: "admin/seasonUpdate",
      data: {
        authToken: yield select(token),
        id: dataObj.data.id,
        stage: AppConfig.stage,
        fields: dataObj.data,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, mssg);
    yield put({ type: MESSAGES.RESPONSE_SEASONUPDATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteSeason(dataObj: any) {
  yield put({ type: REFRESHDATA.MSG, value: "" });
  try {
    let message = {
      action: "admin/seasonDelete",
      data: {
        authToken: yield select(token),
        id: dataObj.data,
        stage: AppConfig.stage,
      },
    };

    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_SEASONDELETE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFetchQuestionList(dataObj: any) {
  try {
    yield put({ type: REFRESHDATA.MSG, value: "" });
    let message = {
      action: "questionsList",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        page: dataObj.data.page,
        keyword: dataObj.data.keyword,
        stack: dataObj.data.stack,
        searchFilters: dataObj.data.searchFilters,
        category: dataObj.data.category,
        subcategory: dataObj.data.subcategory,
        switchFilters: dataObj.data.switchFilters,
        condition: {
          UserId: yield select(userid),
        },
      },
    };

    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_QUESTIONLIST, value: data });
  } catch (error) {
    yield put({ type: MESSAGES.ERROR_QUESTIONLIST });
    yield errorCheck(error);
  }
}

export function* handleFetchSingleQuestion(dataObj: any) {
  try {
    yield put({ type: REFRESHDATA.MSG, value: "" });
    let message = {
      action: "admin/fetchSingleQuestion",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        offset: dataObj.data.offset,
        keyword: dataObj.data.keyword,
        condition: {
          UserId: yield select(userid),
        },
      },
    };

    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_SINGLEQUESTION, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleupdateQuestionList(dataObj: any) {
  try {
    // yield put({ type: MESSAGES.RESPONSE_SINGLEQUESTION, value: data.data });
    let message = {
      action: "admin/questionUpdate",
      data: {
        authToken: yield select(token),
        id: dataObj.data.id,
        stage: AppConfig.stage,
        fields: dataObj.data,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_QUESTIONUPDATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleCreateQuestion(dataObj: any) {
  try {
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    // dataObj.data.authToken = yield select(token);
    // dataObj.data.userid = yield select(userid);
    // dataObj.data.stage = AppConfig.stage;
    // let message = {
    //   action: "admin/createQuestion",
    //   data: dataObj.data,
    // };
    let message = {
      action: "admin/createQuestion",
      data: {
        authToken: yield select(token),
        userid: yield select(userid),
        stage: AppConfig.stage,
        ...dataObj.data,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_QUESTIONCREATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteQuestionList(dataObj: any) {
  console.log("data", dataObj);
  try {
    yield put({ type: REFRESHDATA.MSG, value: "" });
    let message = {
      action: "admin/questionDelete",
      data: {
        authToken: yield select(token),
        id: dataObj.data,
        stage: AppConfig.stage,
      },
    };

    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_QUESTIONDELETE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteMultipleQuestions(dataObj: any) {
  try {
    let message = {
      action: "admin/deleteQuestionMultiple",
      data: {
        authToken: yield select(token),
        ids: dataObj.data,
        stage: AppConfig.stage,
      },
    };

    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: REFRESHDATA.MSG, value: "" });
    yield put({ type: WEBSOCKET.WS_SEND_MESSAGE, value: message });
  } catch (error) {
    console.log("error", error);
    yield put(failure(error.toString()));
  }
}

export function* handleDeletebatchQuestion(dataObj: any) {
  try {
    let message = {
      action: "admin/deleteQuestionMultiple",
      data: {
        authToken: yield select(token),
        StackId: dataObj.data,
        stage: AppConfig.stage,
      },
    };

    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: REFRESHDATA.MSG, value: "" });
    yield put({ type: MESSAGES.RESPONSE_BATCHDELETE, value: data });
  } catch (error) {
    console.log("error", error);
    yield put(failure(error.toString()));
  }
}

export function* handleUploadCSV(dataObj: any) {
  try {
    let message = {
      action: "uploadCSV",
      data: {
        questionLists: dataObj.data,
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };

    if (dataObj.data !== null && dataObj.data !== undefined) {
      yield put({ type: REFRESHDATA.MSG, value: "" });
      // @ts-ignore
      const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
      yield put({ type: MESSAGES.RESPONSE_CSVUPLOADED, value: data });
    }
  } catch (error) {
    yield errorCheck(error);
    // yield put({ type: MESSAGES.CSVUPLOADFAILED, value: error });
  }
}

export function* handleDeleteUserHistory(dataObj: any) {
  try {
    let message = {
      action: "admin/deleteUserHistory",
      data: {
        ...dataObj.data,
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_DELETEUSERHISTORY, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleEpisodeList() {
  try {
    yield put({ type: REFRESHDATA.MSG, value: "" });
    let message = {
      action: "admin/episodesList",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };

    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_EPISODELIST, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleCreateEpisode(dataObj: any) {
  try {
    dataObj.data.authToken = yield select(token);
    dataObj.data.userid = yield select(userid);
    dataObj.data.stage = AppConfig.stage;
    dataObj.action = "admin/createEpisode";
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_EPISODECREATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUpdateEpisode(dataObj: any) {
  try {
    let mssg = {
      action: "admin/episodeUpdate",
      data: {
        authToken: yield select(token),
        id: dataObj.data.id,
        stage: AppConfig.stage,
        fields: dataObj.data,
      },
    };
    console.log("message", mssg);
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, mssg);
    yield put({ type: MESSAGES.RESPONSE_EPISODEUPDATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteEpisode(dataObj: any) {
  console.log("data", dataObj);
  try {
    let message = {
      action: "admin/episodeDelete",
      data: {
        authToken: yield select(token),
        id: dataObj.data,
        stage: AppConfig.stage,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_EPISODEDELETE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleStackList(dataObj: any) {
  try {
    let message = {
      action: "admin/fetchStacks",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        page: dataObj && dataObj.data && dataObj.data.page ? dataObj.data.page : 1,
        keyword: dataObj.data.keyword,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_STACKLIST, value: data });
  } catch (error) {
    yield put({ type: MESSAGES.ERROR_STACKLIST });
    yield errorCheck(error);
  }
}

export function* fetchStackQuestions(dataObj: any) {
  console.log("data", dataObj);
  try {
    let message = {
      action: "admin/fetchStackQuestions",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        ...dataObj.data,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_STACK_QUESTION, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleCreateStackList(dataObj: any) {
  try {
    let message = {
      action: "admin/createStack",
      data: {
        authToken: yield select(token),
        UserId: yield select(userid),
        stage: AppConfig.stage,
        ...dataObj.data,
      },
    };
    // dataObj.data.authToken = yield select(token);
    // dataObj.data.userid = yield select(userid);
    // dataObj.data.stage = AppConfig.stage;
    // dataObj.action = "admin/createStack";
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_STACKCREATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUpdateStackList(dataObj: any) {
  try {
    let mssg = {
      action: "admin/stackUpdate",
      data: {
        authToken: yield select(token),
        id: dataObj.data.id,
        stage: AppConfig.stage,
        fields: dataObj.data,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, mssg);
    yield put({ type: MESSAGES.RESPONSE_STACKUPDATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteStackList(dataObj: any) {
  console.log("data", dataObj);
  try {
    let message = {
      action: "admin/stackDelete",
      data: {
        authToken: yield select(token),
        id: dataObj.data,
        stage: AppConfig.stage,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_STACKDELETE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUpdateNotice(dataObj: any) {
  try {
    let mssg = {
      action: "admin/noticeUpdate",
      data: {
        authToken: yield select(token),
        id: dataObj.data.id,
        stage: AppConfig.stage,
        fields: dataObj.data,
      },
    };
    console.log("message", mssg);
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, mssg);
    yield put({ type: MESSAGES.RESPONSE_NOTICEUPDATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteBanner(dataObj: any) {
  console.log("data", dataObj);
  try {
    let message = {
      action: "admin/bannerDelete",
      data: {
        authToken: yield select(token),
        id: dataObj.data,
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_BANNERSDELETE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteNotice(dataObj: any) {
  console.log("data", dataObj);
  try {
    let message = {
      action: "admin/noticeDelete",
      data: {
        authToken: yield select(token),
        id: dataObj.data,
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_NOTICEDELETE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUserList(dataObj: any) {
  try {
    yield put({ type: QUESTIONLIST.DELTEQUESTIONLSIT });
    let message = {
      action: "admin/usersList",
      data: {
        authToken: yield select(token),
        page: dataObj && dataObj.data && dataObj.data.page ? dataObj.data.page : 1,
        keyword: dataObj.data.keyword,
        stage: AppConfig.stage,
      },
    };
    console.log("message::::>", message);
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_USERLIST, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleCreateUser(dataObj: any) {
  try {
    let mssg = {
      action: "admin/addNewUser",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        user: dataObj.data,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, mssg);
    yield put({ type: MESSAGES.RESPONSE_USERLISTAdd, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUpdateUserList(dataObj: any) {
  try {
    let mssg = {
      action: "admin/updateUser",
      data: {
        authToken: yield select(token),
        id: dataObj.data.id,
        stage: AppConfig.stage,
        fields: dataObj.data,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, mssg);
    yield put({ type: MESSAGES.RESPONSE_USERLISTUPDATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUpdateBanner(dataObj: any) {
  try {
    let mssg = {
      action: "admin/bannerUpdate",
      data: {
        authToken: yield select(token),
        id: dataObj.data.id,
        stage: AppConfig.stage,
        fields: dataObj.data,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, mssg);
    yield put({ type: MESSAGES.RESPONSE_BANNERSUPDATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFetchAppConfig() {
  try {
    let message = {
      action: "appConfigs",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_AppConfig, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleCreateAppConfig(dataObj: any) {
  try {
    dataObj.data.authToken = yield select(token);
    dataObj.data.userid = yield select(userid);
    dataObj.data.stage = AppConfig.stage;
    let message = {
      action: "admin/createAppConfig",
      data: dataObj.data,
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_AppConfigCREATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUpdateAppConfig(dataObj: any) {
  try {
    let mssg = {
      action: "admin/updateAppConfig",
      data: {
        authToken: yield select(token),
        id: dataObj.data.id,
        stage: AppConfig.stage,
        fields: dataObj.data,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, mssg);
    yield put({ type: MESSAGES.RESPONSE_AppConfigUPDATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteAppConfig(dataObj: any) {
  console.log("data", dataObj);
  try {
    let message = {
      action: "admin/deleteAppConfig",
      data: {
        authToken: yield select(token),
        id: dataObj.data,
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.FAILURE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFetchPrize() {
  try {
    let message = {
      action: "admin/fetchGamePrize",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_FETCHPRIZE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleCreatePrize(dataObj: any) {
  try {
    dataObj.data.authToken = yield select(token);
    dataObj.data.userid = yield select(userid);
    dataObj.data.stage = AppConfig.stage;
    let message = {
      action: "admin/createGamePrize",
      data: dataObj.data,
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_CREATEPRIZE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUpdatePrize(dataObj: any) {
  try {
    let mssg = {
      action: "admin/updateGamePrize",
      data: {
        authToken: yield select(token),
        id: dataObj.data.id,
        stage: AppConfig.stage,
        fields: dataObj.data,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, mssg);
    yield put({ type: MESSAGES.RESPONSE_UPDATEPRIZE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeletePrize(dataObj: any) {
  console.log("data", dataObj);
  try {
    let message = {
      action: "admin/deleteGamePrize",
      data: {
        authToken: yield select(token),
        id: dataObj.data,
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_DELETEPRIZE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUserFetchReport(dataObj: any) {
  console.log("dataObjuserreport", dataObj);
  try {
    let message = {
      action: "admin/userReports",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        keyword: dataObj.data.keyword,
        GameId: dataObj.data.GameId,
        from_date: dataObj.data.from_date,
        to_date: dataObj.data.to_date,
        category: dataObj.data.category,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_FETCHREPORT, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDownloadUserReport(dataObj: any) {
  console.log("dataObj", dataObj);
  try {
    let message = {
      action: "admin/downloadUserReport",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        GameId: dataObj.data.GameId,
        from_date: dataObj.data.from_date,
        to_date: dataObj.data.to_date,
        category: dataObj.data.category,
        page: dataObj.data.page,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_LINK, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDownloadQuestions(dataObj: any) {
  try {
    yield put({ type: MSG.CLEAR_MSG, value: "" });
    let message = {
      action: "admin/downloadQuestions",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        GameId: dataObj.data.GameId,
        StackId: dataObj.data.StackId,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.QUESTION_LINK, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}
export function* handleDownloadUsers() {
  try {
    yield put({ type: MSG.CLEAR_MSG, value: "" });
    let message = {
      action: "admin/downloadUsers",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.USER_LINK, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFetchAvatar() {
  try {
    let message = {
      action: "avatarsList",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("data", data);
    yield put({ type: MESSAGES.RESPONSE_AVATARLIST, data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleCreateAvatar(dataObj: any) {
  console.log("dataDownload Queston", dataObj);
  try {
    let message = {
      action: "admin/createAvatar",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        avatarImageUrl: dataObj.data,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_AVATARLIST_CREATED, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteAvatar(dataObj: any) {
  console.log("dataDownload Queston", dataObj);
  try {
    let message = {
      action: "admin/deleteAvatar",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        id: dataObj.data,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_AVATARLIST_DELETED, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteUser(dataObj: any) {
  console.log("deleted user", dataObj);
  try {
    let message = {
      action: "admin/deleteUser",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        id: dataObj.data,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_USERLISTDELETE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFetchDefaultParameters() {
  try {
    let message = {
      action: "admin/gameSetting",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };
    // yield put({ type: WEBSOCKET.WS_SEND_MESSAGE, value: message });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("defauleparameterslist", data);
    yield put({ type: MESSAGES.RESPONSE_FETCH_PARAMETERS, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUpdateDefaultParameters(dataObj: any) {
  console.log("dataDownload Queston", dataObj);
  try {
    let message = {
      action: "admin/updateGameSetting",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        data: dataObj.data,
      },
    };
    yield put({ type: WEBSOCKET.WS_SEND_MESSAGE, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("UpdateDefaultParameters", data);
    yield put({ type: MESSAGES.RESPONSE_UPDATED_PARAMETERS, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleWinnerList(dataObj: any) {
  console.log("dataObj", dataObj);
  try {
    let message = {
      action: "admin/getWinners",
      data: {
        authToken: yield select(token),
        // stage: AppConfig.stage,
        // page: dataObj && dataObj.data && dataObj.data.page ? dataObj.data.page : 1,
        // keyword: dataObj.data.keyword,
        game_id: dataObj.data.game_id,
        stage: "PRODUCTION",
        user_type: dataObj.data.user_type,
        day_type: dataObj.data.day_type,
        date: dataObj.data.date,
        keyword: dataObj.data.keyword,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("winnerlist", data);
    yield put({ type: MESSAGES.RESPONSE_WINNERLIST, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleUpdateWinner(dataObj: any) {
  console.log("dataObj", dataObj);
  try {
    let message = {
      action: "admin/updateRank",
      data: {
        authToken: yield select(token),
        // stage: AppConfig.stage,
        // page: dataObj && dataObj.data && dataObj.data.page ? dataObj.data.page : 1,
        // keyword: dataObj.data.keyword,
        game_id: dataObj.data.game_id,
        stage: "PRODUCTION",
        user_type: dataObj.data.user_type,
        day_type: dataObj.data.day_type,
        updated_rank: dataObj.data.updated_rank,
        user_id: dataObj.data.user_id,
        date: dataObj.data.date,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("winnerlist updated", data);
    yield put({ type: MESSAGES.RESPONSE_WINNERLISTUPDATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handlePublishWinner(dataObj: any) {
  console.log("dataObj", dataObj);
  try {
    let message = {
      action: "admin/publishWinners",
      data: {
        authToken: yield select(token),
        game_id: dataObj.data.game_id,
        stage: "PRODUCTION",
        day_type: dataObj.data.day_type,
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("winnerlist published", data);
    yield put({ type: MESSAGES.RESPONSE_WINNERLISTPUBLISH, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}
export function* handleWinnersPublishManage(dataObj: any) {
  console.log("dataObj", dataObj);
  dataObj.data.authToken= yield select(token)
  dataObj.data.stage= "PRODUCTION"
  try {
    let message = {
      action: "admin/winnersList",
      ...dataObj
    };
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    console.log("message",message)
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("winnerlist published message", data);
    yield put({ type: MESSAGES.RESPONSE_WINNERPUBLISHMANAGE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}


export function* handleAddWinners(dataObj: any){
  console.log("dataObj", dataObj);
  dataObj.data.authToken= yield select(token)
  dataObj.data.stage= "PRODUCTION"
  try {
    let message = {
      action: "admin/uploadWinnersCSV",
       ...dataObj
    };
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("winnerlist added", data);
    yield put({ type: MESSAGES.RESPONSE_WINNERLISTADD, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* manageWinnersPublish(dataObj:any){
  try {
    // @ts-ignore
    dataObj.data.authToken = yield select(token)
    dataObj.data.stage= "PRODUCTION"
    let message = {
      action: "admin/updateWinnerBoard",
        ...dataObj
    };
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("winnerlist deleted", data);
    yield put({ type: MESSAGES.RESPONSE_MANAGE_PUBLISH_WINNERLIST, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteWinners(dataObj:any){
  try {
    let message = {
      action: "admin/deleteWinnersBoard",
      data: {
        authToken: yield select(token),
        stage: "PRODUCTION",
        week: dataObj.data, 
      },
    };
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("winnerlist deleted", data);
    yield put({ type: MESSAGES.RESPONSE_WINNERLISTDELETE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleSchoolList(dataObj: any) {
  console.log("dataObj", dataObj);
  try {
    let message = {
      action: "admin/getSchoolsList",
      data: {
        authToken: yield select(token),
        keyword: dataObj.data.keyword,
        created_by: dataObj.data.created_by,
        searchFilters:dataObj.data.searchFilters,
        switchFilters:dataObj.data.switchFilters,
        stage: "PRODUCTION",
        zone: dataObj.data.zone,
        page:dataObj.data.page||1,
        limit:dataObj.data.limit||100
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("schoollist", data);
    yield put({ type: MESSAGES.RESPONSE_SCHOOLLIST, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}


export function* handleFAQList(dataObj: any) {
  console.log("dataObjfaqList", dataObj);
  try {
    let message = {
      action: "admin/getFaq",
      data: {
        authToken: yield select(token),
        stage: "PRODUCTION",
        id:"1",
        ...dataObj
      },
    };
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("FAQlist", data);
    yield put({ type: MESSAGES.RESPONSE_FAQLIST, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}


export function* handleCreateFAQ(dataObj: any) {
  try {
    let message = {
      action: "admin/Faq",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        data: {...dataObj.data},
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_FAQCREATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleCreateSchool(dataObj: any) {
  try {
    let message = {
      action: "admin/createSchool",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        ...dataObj.data,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_SCHOOLCREATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleupdateSchoolList(dataObj: any) {
  console.log("dataObj", dataObj);
  try {
    // yield put({ type: MESSAGES.RESPONSE_SINGLEQUESTION, value: data.data });
    let message = {
      action: "admin/updateSchool",
      data: {
        authToken: yield select(token),
        old_school_code: dataObj.data.oldschoolid,
        stage: AppConfig.stage,
        fields: {
          address: dataObj.data.address,
          area: dataObj.data.area,
          chain: dataObj.data.chain,
          code: dataObj.data.code,
          email: dataObj.data.email,
          name: dataObj.data.name,
          phone: dataObj.data.phone,
          pincode: dataObj.data.pincode,
          state: dataObj.data.state,
        },
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_SCHOOLUPDATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleupdateFAQList(dataObj: any) {
  console.log("dataObj", dataObj);
  try {
    // yield put({ type: MESSAGES.RESPONSE_SINGLEQUESTION, value: data.data });
    let message = {
      action: "admin/Faq",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        data:{...dataObj.data}
      },
    };
    yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_FAQUPDATE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}


export function* handleDeleteFAQ(dataObj: any) {
  console.log("deleted FAQ", dataObj);
  try {
    let message = {
      action: "admin/deleteFaq",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        id: dataObj.data,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_FAQDELETE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleDeleteSchool(dataObj: any) {
  console.log("deleted school", dataObj);
  try {
    let message = {
      action: "admin/deleteSchool",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        code: dataObj.data,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: MESSAGES.RESPONSE_SCHOOLDELETE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFeedbackList(dataObj: any) {
  console.log("handleFeedbackList", dataObj);
  try {
    let message = {
      action: "admin/feedbackList",
      data: {
        authToken: yield select(token),
        stage: "PRODUCTION",
        keyword: dataObj.data.keyword,
      },
    };
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("feedbacklist", data);
    yield put({ type: MESSAGES.RESPONSE_FEEDBACKLIST, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleFeedbackDownload() {
  try {
    let message = {
      action: "admin/downloadFeedbackReport",
      data: {
        authToken: yield select(token),
        page: 1,
        stage: "PRODUCTION",
      },
    };
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("feedbacklist", data);
    yield put({ type: MESSAGES.DOWNLOAD_FEEDBACKLIST, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleSchoollistDownload(dataObj: any) {
  try {
    let message = {
      action: "admin/downloadSchoolList",
      data: {
        authToken: yield select(token),
        keyword: dataObj.data.keyword,
        stage: "PRODUCTION",
      },
    };
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("downloadSchoolList", data);
    yield put({ type: MESSAGES.RESPONSE_SCHOOLDOWNLOAD, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handleSchoolMerge(dataObj: any) {
  try {
    let message = {
      action: "admin/mergeSchools",
      data: {
        authToken: yield select(token),
        school_to_be_merged: dataObj.data.school_to_be_merged,
        school_merge_with: dataObj.data.school_merge_with,
        stage: "PRODUCTION",
      },
    };
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("downloadSchoolList", data);
    yield put({ type: MESSAGES.RESPONSE_SCHOOLMERGE, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handlePaymentlist(dataObj: any) {
  try {
    let message = {
      action: "admin/fetchPaymentList",
      data: {
        authToken: yield select(token),
        keyword: dataObj.data.keyword,
        stage: "PRODUCTION",
      },
    };
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("downloadSchoolList", data);
    yield put({ type: MESSAGES.RESPONSE_PAYMENTLIST, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export function* handlePaymentlistDownload(dataObj: any) {
  try {
    let message = {
      action: "admin/downloadPaymentList",
      data: {
        authToken: yield select(token),
        keyword: dataObj.data.keyword,
        stage: "PRODUCTION",
      },
    };
    // yield put({ type: REFRESHDATA.MSG, value: "" });
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    console.log("downloadSchoolList", data);
    yield put({ type: MESSAGES.RESPONSE_PAYMENTLISTDOWNLOAD, value: data });
  } catch (error) {
    yield errorCheck(error);
  }
}

export default function* watchers() {
  yield takeEvery(AUTH.REQUEST_ADMIN_LOGIN, (data) => handleLogin(data));
  yield takeEvery(COUNTS.REQUEST_FETCH_COUNTS, handleAllCounts);
  yield takeEvery(ANALYTICS.REQUEST_FETCH_ANALYTICS, (data) => handleFetchAnalytics(data));

  yield takeEvery(NOTICEBOARD.REQUEST_FETCH_NOTICEBOARD, handleFetchNoticeBoard);
  yield takeEvery(NOTICEBOARD.CREATE_NOTICEBOARD, (data) => handleAddNotice(data));
  yield takeEvery(NOTICEBOARD.UPDATE_NOTICEBOARD, (data) => handleUpdateNotice(data));
  yield takeEvery(NOTICEBOARD.DELETE_NOTICEBOARD, (data) => handleDeleteNotice(data));

  yield takeEvery(BANNERS.REQUEST_FETCH_BANNERS, handleFetchBanners);
  yield takeEvery(BANNERS.CREATE_BANNERS, (data) => handleAddBanner(data));
  yield takeEvery(BANNERS.UPDATE_BANNERS, (data) => handleUpdateBanner(data));
  yield takeEvery(BANNERS.DELETE_BANNERS, (data) => handleDeleteBanner(data));

  yield takeEvery(GAMELIST.REQUEST_FETCH_GAMELIST, (data) => handleFetchGameList(data));
  yield takeEvery(GAMELIST.CREATE_GAMELIST, (data) => handleCreateGame(data));
  yield takeEvery(GAMELIST.UPDATE_GAMELIST, (data) => handleUpdateGame(data));
  yield takeEvery(GAMELIST.DELETE_GAMELIST, (data) => handleDeleteGame(data));
  yield takeEvery(GAMELIST.UPDATE_GAMEPOSITION, (data) => handleUpdateGamePosition(data));

  yield takeEvery(SEASONLIST.REQUEST_FETCH_SEASONLIST, handleFetchSeasonList);
  yield takeEvery(SEASONLIST.CREATE_SEASON, (data) => handleCreateSeason(data));
  yield takeEvery(SEASONLIST.UPDATE_SEASON, (data) => handleUpdateSeason(data));
  yield takeEvery(SEASONLIST.DELETE_SEASON, (data) => handleDeleteSeason(data));

  yield takeEvery(QUESTIONLIST.REQUEST_FETCH_QUESTIONLIST, (data) => handleFetchQuestionList(data));
  // yield takeEvery(QUESTIONLIST.REQUEST_FETCH_SINGLEQUESTION, (data) =>
  //   handleFetchSingleQuestion(data)
  // );

  yield takeEvery(QUESTIONLIST.CREATE_QUESTIONLIST, (data) => handleCreateQuestion(data));
  yield takeEvery(QUESTIONLIST.UPDATE_QUESTIONLIST, (data) => handleupdateQuestionList(data));
  yield takeEvery(QUESTIONLIST.DELETE_QUESTIONLIST, (data) => handleDeleteQuestionList(data));
  yield takeEvery(QUESTIONLIST.DELETE_MULTIPLEQUESTIONLIST, (data) =>
    handleDeleteMultipleQuestions(data)
  );
  yield takeEvery(QUESTIONLIST.UPLOAD_CSV, (data) => handleUploadCSV(data));
  yield takeEvery(QUESTIONLIST.DELETE_BATCHQUESTIONS, (data) => handleDeletebatchQuestion(data));

  yield takeEvery(EPISODELIST.REQUEST_FETCH_EPISODE, handleEpisodeList);
  yield takeEvery(EPISODELIST.CREATE_EPISODE, handleCreateEpisode);
  yield takeEvery(EPISODELIST.UPDATE_EPISODE, (data) => handleUpdateEpisode(data));
  yield takeEvery(EPISODELIST.DELETE_EPISODE, (data) => handleDeleteEpisode(data));

  yield takeEvery(STACKLIST.REQUEST_FETCH_STACKLIST, (data) => handleStackList(data));
  yield takeEvery(STACKLIST.CREATE_STACKLIST, handleCreateStackList);
  yield takeEvery(STACKLIST.UPDATE_STACKLIST, (data) => handleUpdateStackList(data));
  yield takeEvery(STACKLIST.DELETE_STACKLIST, (data) => handleDeleteStackList(data));
  yield takeEvery(STACKLIST.FETCH_STACK_QUESTION, (data) => fetchStackQuestions(data));
  yield takeEvery(USERLIST.REQUEST_FETCH_USERLIST, (data) => handleUserList(data));
  yield takeEvery(USERLIST.CREATE_USERLIST, (data) => handleCreateUser(data));
  yield takeEvery(USERLIST.UPDATE_USERLIST, (data) => handleUpdateUserList(data));
  yield takeEvery(USERLIST.DELETE_USERLIST, (data) => handleDeleteUser(data));

  yield takeEvery(APPCONFIG.REQUEST_FETCH_APPCONFIG, handleFetchAppConfig);
  yield takeEvery(APPCONFIG.CREATE_APPCONFIG, (data) => handleCreateAppConfig(data));
  yield takeEvery(APPCONFIG.UPDATE_APPCONFIG, (data) => handleUpdateAppConfig(data));
  yield takeEvery(APPCONFIG.DELETE_APPCONFIG, (data) => handleDeleteAppConfig(data));

  yield takeEvery(PRIZE.REQUEST_FETCH_PRIZE, handleFetchPrize);
  yield takeEvery(PRIZE.CREATE_PRIZE, (data) => handleCreatePrize(data));
  yield takeEvery(PRIZE.UPDATE_PRIZE, (data) => handleUpdatePrize(data));
  yield takeEvery(PRIZE.DELETE_PRIZE, (data) => handleDeletePrize(data));
  yield takeEvery(REPORT.FETCH_REPORT, (data) => handleUserFetchReport(data));
  yield takeEvery(REPORT.FETCHDOWNLOADUSERREPORT, (data) => handleDownloadUserReport(data));
  yield takeEvery(QUESTIONLIST.DOWNLOAD_QUESTION, (data) => handleDownloadQuestions(data));
  yield takeEvery(REPORT.DOWNLOADUSERS, handleDownloadUsers);
  yield takeEvery(REPORT.DELETEUSERHISTORY, (data) => handleDeleteUserHistory(data));

  yield takeEvery(AVATAR.FETCH_AVATAR, handleFetchAvatar);
  yield takeEvery(AVATAR.CREATE_AVATAR, (data) => handleCreateAvatar(data));
  yield takeEvery(AVATAR.DELETE_AVATAR, (data) => handleDeleteAvatar(data));
  yield takeEvery(DEFAULT_PARAMETERS.FETCH_PARAMETERS, handleFetchDefaultParameters);
  yield takeEvery(DEFAULT_PARAMETERS.UPDATE_PARAMETERS, (data) =>
    handleUpdateDefaultParameters(data)
  );

  yield takeEvery(WINNERLIST.REQUEST_FETCH_WINNERLIST, (data) => handleWinnerList(data));
  yield takeEvery(WINNERLIST.UPDATE_WINNERLIST, (data) => handleUpdateWinner(data));
  yield takeEvery(WINNERLIST.PUBLISH_WINNERLIST, (data) => handlePublishWinner(data));
  yield takeEvery(WINNERLIST.ADD_WINNERLIST, (data) => handleAddWinners(data));
  yield takeEvery(WINNERLIST.WINNER_PUBLISH, (data) => handleWinnersPublishManage(data));
  yield takeEvery(WINNERLIST.DELETE_WINNERLIST, (data) => handleDeleteWinners(data));
  yield takeEvery(WINNERLIST.MANAGE_PUBLISH_WINNERLIST, (data) => manageWinnersPublish(data));

  yield takeEvery(SCHOOLLIST.REQUEST_FETCH_SCHOOLLIST, (data) => handleSchoolList(data));
  yield takeEvery(SCHOOLLIST.CREATE_SCHOOLLIST, (data) => handleCreateSchool(data));
  yield takeEvery(SCHOOLLIST.UPDATE_SCHOOLLIST, (data) => handleupdateSchoolList(data));
  yield takeEvery(SCHOOLLIST.DELETE_SCHOOLLIST, (data) => handleDeleteSchool(data));
  yield takeEvery(SCHOOLLIST.DOWNLOAD_SCHOOLLIST, (data) => handleSchoollistDownload(data));
  yield takeEvery(SCHOOLLIST.MERGE_SCHOOLLIST, (data) => handleSchoolMerge(data));

  yield takeEvery(FEEDBACK.REQUEST_FETCH_FEEDBACK, (data) => handleFeedbackList(data));
  yield takeEvery(FEEDBACK.DOWNLOAD_FEEDBACK, handleFeedbackDownload);

  yield takeEvery(PAYMENT.REQUEST_FETCH_PAYMENT, (data) => handlePaymentlist(data));
  yield takeEvery(PAYMENT.DOWNLOAD_PAYMENT, (data) => handlePaymentlistDownload(data));

  yield takeEvery(FAQLIST.CREATE_FAQLIST, (data) => handleCreateFAQ(data));
  yield takeEvery(FAQLIST.REQUEST_FETCH_FAQLIST, (data) => handleFAQList(data));
  yield takeEvery(FAQLIST.UPDATE_FAQLIST, (data) => handleupdateFAQList(data));
  yield takeEvery(FAQLIST.DELETE_FAQLIST, (data) => handleDeleteFAQ(data));

  // yield takeEvery(PAYMENT.DOWNLOAD_PAYMENT, (data) => handlePaymentlistDownload(data));
  // yield takeEvery(PAYMENT.DOWNLOAD_PAYMENT, (data) => handlePaymentlistDownload(data));
}
