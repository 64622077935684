import AWS from "aws-sdk";

const AppConfig = {
  BaseUrl: process.env.REACT_APP_API_ENDPOINT as string,
  stage: "PRODUCTION",
  s3: new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGION,
  }),
  ImageUrl: `https://${process.env.REACT_APP_BUCKET_NAME}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/QuestionMedia/`,
  showImageUrl: `https://${process.env.REACT_APP_BUCKET_NAME}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/`,

};
console.log("process.env.REACT_APP_BUCKET_NAME",process.env.REACT_APP_BUCKET_NAME)
export default AppConfig;
