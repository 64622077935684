import axios from "axios";
import { put, takeEvery, select, call } from "redux-saga/effects";
import AppConfig from "../../configs/AppConfig";
import { MESSAGES, IMAGE_LIST, IMAGE_QUESTION } from "../constants/constants";

export const token = (state: any) => state.adminReducer.token;

function* errorCheck(error: any) {
  console.log("error", error?.response?.data?.msg);
  yield put({
    type: MESSAGES.ERROR,
    error: error?.response?.data?.msg,
    status: error?.response?.status,
  });
}

export function* handleFetchImageList() {
  try {
    let message = {
      action: "admin/getQuestionImagesList",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: IMAGE_LIST.SUCCESS_IMAGE_LIST, value: data });
  } catch (error) {
    yield put({ type: IMAGE_LIST.FAILURE_IMAGE_LIST, value: error });
    yield errorCheck(error);
  }
}

export function* handleFetchQuestionByImageURL(imageURL: string) {
  try {
    let message = {
      action: "admin/fetchQuestionByMedia",
      data: {
        authToken: yield select(token),
        stage: AppConfig.stage,
        preOptionsMedia: imageURL,
      },
    };
    // @ts-ignore
    const { data } = yield call(axios.post, AppConfig.BaseUrl, message);
    yield put({ type: IMAGE_QUESTION.SUCCESS_IMAGE_QUESTION, value: data });
  } catch (error) {
    yield put({ type: IMAGE_QUESTION.FAILURE_IMAGE_QUESTION, value: error });
    yield errorCheck(error);
  }
}

export default function* watchers() {
  yield takeEvery(IMAGE_LIST.REQUEST_IMAGE_LIST, handleFetchImageList);

  // @ts-ignore
  yield takeEvery(IMAGE_QUESTION.REQUEST_IMAGE_QUESTION, (action) => handleFetchQuestionByImageURL(action.data));
}
