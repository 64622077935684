/**
 * Enums for roles
 */
export const Role = {
  Reports: "reports",
  DeleteGameHistory: "delete_game_history",
  AppConfig: "app_config",
  ManageGames: "manage_games",
  SuperUser: "*",
} as const;

export type Role = typeof Role[keyof typeof Role];
