import { IMAGE_LIST, IMAGE_QUESTION } from "../constants/ImagesReduxConstants";

export const token = (state: any) => state.adminReducer.token;

export const requestImageList = () => ({
  type: IMAGE_LIST.REQUEST_IMAGE_LIST,
});

export const requestQuestionByImageURL = (imageURL: string) => ({
  type: IMAGE_QUESTION.REQUEST_IMAGE_QUESTION,
  data: imageURL,
});

export const resetQuestionImageState = () => ({
  type: IMAGE_QUESTION.SET_IMAGE_QUESTION,
});

export const setImageListState = (key: string, value: any) => ({
  type: IMAGE_LIST.SET_IMAGE_LIST,
  key,
  value,
});
