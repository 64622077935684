import { IMAGE_LIST, IMAGE_QUESTION } from "./ImagesReduxConstants";

export const SET_THEME = "SET_THEME";

const AUTH = {
  REMEMBER_ME: "REMEMBER_ME",
  REQUEST_ADMIN_LOGIN: "REQUEST_ADMIN_LOGIN",
  SUCCESS_ADMIN_LOGIN: "SUCCESS_ADMIN_LOGIN",
  FAILURE_ADMIN_LOGIN: "FAILURE_ADMIN_LOGIN",
  REQUEST_ADMIN_LOGOUT: "REQUEST_ADMIN_LOGOUT",
};

const ANALYTICS = {
  REQUEST_FETCH_ANALYTICS: "REQUEST_FETCH_ANALYTICS",
};

const AVATAR = {
  FETCH_AVATAR: "FETCH_AVATAR",
  RESPONSE_AVATAR: "RESPONSE_AVATAR",
  CREATE_AVATAR: "CREATE_AVATAR",
  DELETE_AVATAR: "DELETE_AVATAR",
};

const DEFAULT_PARAMETERS = {
  FETCH_PARAMETERS: "FETCH_PARAMETERS",
  RESPONSE_PARAMETERS: "RESPONSE_PARAMETERS",
  UPDATE_PARAMETERS: "UPDATE_PARAMETERS",
};

const MSG = {
  CLEAR_MSG: "CLEAR_MSG",
};

const NOTICEBOARD = {
  REQUEST_FETCH_NOTICEBOARD: "REQUEST_FETCH_NOTICEBOARD",
  CREATE_NOTICEBOARD: "CREATE_NOTICEBOARD",
  UPDATE_NOTICEBOARD: "UPDATE_NOTICEBOARD",
  DELETE_NOTICEBOARD: "DELETE_NOTICEBOARD",
};

const BANNERS = {
  REQUEST_FETCH_BANNERS: "REQUEST_FETCH_BANNERS",
  CREATE_BANNERS: "CREATE_BANNERS",
  UPDATE_BANNERS: "UPDATE_BANNERS",
  DELETE_BANNERS: "DELETE_BANNERS",
};

const FEEDBACK = {
  REQUEST_FETCH_FEEDBACK: "REQUEST_FETCH_FEEDBACK",
  DOWNLOAD_FEEDBACK: "DOWNLOAD_FEEDBACK",
};

const PAYMENT = {
  REQUEST_FETCH_PAYMENT: "REQUEST_FETCH_PAYMENT",
  DOWNLOAD_PAYMENT: "DOWNLOAD PAYMENT",
};

const GAMELIST = {
  REQUEST_FETCH_GAMELIST: "REQUEST_FETCH_GAMELIST",
  CREATE_GAMELIST: "CREATE_GAMELIST",
  UPDATE_GAMELIST: "UPDATE_GAMELIST",
  DELETE_GAMELIST: "DELETE_GAMELIST",
  UPDATE_GAMEPOSITION: "UPDATE_GAMEPOSITION",
  DELETE_GAME: "DELETE_GAME",
};

const GAMESTATUS ={
  UPDATE_GAMESTATUS: "UPDATE_GAMESTATUS",
}

const GAMEDISPLAY ={
  UPDATE_GAMEDISPLAY: "UPDATE_GAMEDISPLAY",
}
const USERCOUNT ={
  UPDATE_USERCOUNT: "UPDATE_USERCOUNT",
}
const OPTIONANALYTICS ={
  UPDATE_OPTIONANALYTICS: "UPDATE_OPTIONANALYTICS",
}
const ELIGIBILITYCHECK ={
  UPDATE_ELIGIBILITYCHECK: "UPDATE_ELIGIBILITYCHECK",
}
const STACKLIST = {
  REQUEST_FETCH_STACKLIST: "REQUEST_FETCH_STACKLIST",
  CREATE_STACKLIST: "CREATE_STACKLIST",
  UPDATE_STACKLIST: "UPDATE_STACKLIST",
  DELETE_STACKLIST: "DELETE_STACKLIST",
  FETCH_STACK_QUESTION: "FETCH_STACK_QUESTION",
};

const WINNERLIST = {
  REQUEST_FETCH_WINNERLIST: "REQUEST_FETCH_WINNERLIST",
  UPDATE_WINNERLIST: "UPDATE_WINNERLIST",
  PUBLISH_WINNERLIST: "PUBLISH_WINNERLIST",
  ADD_WINNERLIST:"ADD_WINNERLIST",
  WINNER_PUBLISH:"WINNER_PUBLISH",
  DELETE_WINNERLIST:"DELETE_WINNERLIST",
  MANAGE_PUBLISH_WINNERLIST:"MANAGE_PUBLISH_WINNERLIST",
};

const SCHOOLLIST = {
  REQUEST_FETCH_SCHOOLLIST: "REQUEST_FETCH_SCHOOLLIST",
  CREATE_SCHOOLLIST: "CREATE_SCHOOLLIST",
  UPDATE_SCHOOLLIST: "UPDATE_SCHOOLLIST",
  DELETE_SCHOOLLIST: "DELETE_SCHOOLLIST",
  DOWNLOAD_SCHOOLLIST: "DOWNLOAD_SCHOOLLIST",
  MERGE_SCHOOLLIST: "MERGE_SCHOOLLIST",
};

const FAQLIST = {
  REQUEST_FETCH_FAQLIST: "REQUEST_FETCH_FAQLIST",
  CREATE_FAQLIST: "CREATE_FAQLIST",
  UPDATE_FAQLIST: "UPDATE_FAQLIST",
  DELETE_FAQLIST: "DELETE_FAQLIST",
};

const QUESTIONLIST = {
  DELTEQUESTIONLSIT: "DELTEQUESTIONLSIT",
  REQUEST_FETCH_QUESTIONLIST: "REQUEST_FETCH_QUESTIONLIST",
  REQUEST_FETCH_SINGLEQUESTION: "REQUEST_FETCH_SINGLEQUESTION",
  CREATE_QUESTIONLIST: "CREATE_QUESTIONLIST",
  UPDATE_QUESTIONLIST: "UPDATE_QUESTIONLIST",
  DELETE_QUESTIONLIST: "DELETE_QUESTIONLIST",
  DELETE_MULTIPLEQUESTIONS: "DELETE_MULTIPLEQUESTIONS",
  UPLOAD_CSV: "UPLOAD_CSV",
  DOWNLOAD_QUESTION: "DOWNLOAD_QUESTION",
  NULLQUESTIONLIST: "NULLQUESTIONLIST",
  SINGLE: "SINGLE",
  DELETE_MULTIPLEQUESTIONLIST: "DELETE_MULTIPLEQUESTIONLIST",

  DELETE_BATCHQUESTIONS: "DELETE_BATCHQUESTIONS",
};

const SEASONLIST = {
  REQUEST_FETCH_SEASONLIST: "REQUEST_FETCH_SEASONLIST",
  CREATE_SEASON: "CREATE_SEASON",
  UPDATE_SEASON: "UPDATE_SEASON",
  DELETE_SEASON: "DELETE_SEASON",
};

const EPISODELIST = {
  REQUEST_FETCH_EPISODE: "REQUEST_FETCH_EPISODE",
  CREATE_EPISODE: "CREATE_EPISODE",
  UPDATE_EPISODE: "UPDATE_EPISODE",
  DELETE_EPISODE: "DELETE_EPISODE",
};

const USERLIST = {
  REQUEST_FETCH_USERLIST: "REQUEST_FETCH_USERLIST",
  CREATE_USERLIST: "CREATE_USERLIST",
  UPDATE_USERLIST: "UPDATE_USERLIST",
  DELETE_USERLIST: "DELETE_USERLIST",
};

const USER = {
  REQUEST_FETCH_USER: "REQUEST_FETCH_USER",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
};

const APPCONFIG = {
  REQUEST_FETCH_APPCONFIG: "REQUEST_FETCH_APPCONFIG",
  CREATE_APPCONFIG: "CREATE_APPCONFIG",
  UPDATE_APPCONFIG: "UPDATE_APPCONFIG",
  DELETE_APPCONFIG: "DELETE_APPCONFIG",
};

const PRIZE = {
  REQUEST_FETCH_PRIZE: "REQUEST_FETCH_PRIZE",
  CREATE_PRIZE: "CREATE_PRIZE",
  UPDATE_PRIZE: "UPDATE_PRIZE",
  DELETE_PRIZE: "DELETE_PRIZE",
};

const COUNTS = {
  REQUEST_FETCH_COUNTS: "REQUEST_FETCH_COUNTS",
};

const THEME = {
  SET_THEME: "SET_THEME",
};

const WEBSOCKET = {
  WELCOME: "WELCOME",
  SET_CONNECTED: "SET_CONNECTED",
  LOAD_WEBSOCKET: "LOAD_WEBSOCKET",
  WS_CONNECTED: "WS_CONNECTED",
  WS_DISCONNECTED: "WS_DISCONNECTED",
  WS_MESSAGE: "WS_MESSAGE",
  WS_SEND_MESSAGE: "WS_SEND_MESSAGE",
};

const REFRESHDATA = {
  MSG: "MSG",
};

const REPORT = {
  FETCH_REPORT: "FETCH_REPORT",
  FETCHDOWNLOADUSERREPORT: "FETCHDOWNLOADUSERREPORT",
  DOWNLOADUSERS: "DOWNLOADUSERS",
  DELETEUSERHISTORY: "DELETEUSERHISTORY",
};

const MESSAGES = {
  FAILURE: "FAILURE",
  DIGITOK_ANALYTICS: "DIGITOK_ANALYTICS",
  LOGIN_SUCCESS: "Successfully logged in!",
  RESPONSE_COUNT: "Count found",
  RESPONSE_NOTICEBOARD: "NoticeBoard list found",
  RESPONSE_NOTICECREATE: "NoticeBoard Created Successfully",
  RESPONSE_NOTICEUPDATE: "Notice Updated",
  RESPONSE_NOTICEDELETE: "Notice Deleted",
  RESPONSE_BANNERS: "Banner list found",
  RESPONSE_BANNERSCREATE: "Banner Created Successfully",
  RESPONSE_BANNERSDELETE: "Banner deleted",
  RESPONSE_BANNERSUPDATE: "Banner Updated",
  RESPONSE_ALLCOUNT: "update game",
  RESPONSE_ADDBANNER: "",
  RESPONSE_DELETEUSERHISTORY: "User history deleted successfully",
  RESPONSE_ERRDELETEUSERHISTORY: "Could not delete user history!",
  RESPONSE_ERRDELETEUSERHISTORY1: "Quiz Not Found",
  RESPONSE_ERRDELETEUSERHISTORY2: "Game not found",
  RESPONSE_ERRDELETEUSERHISTORY3: "Users Not Found",
  RESPONSE_ADDNOTICEBOARD: "",
  PASSWORD_WRONG: "Password is wrong!",
  ERROR: "common Error.",
  ERRORLOGIN: "Error in login!",
  RESPONSE_GAMELIST: "Game list found",
  RESPONSE_GAMECREATE: "Game created successfully!",
  RESPONSE_CREATEGAME: "Game added",
  RESPONSE_UPDATEGAME: "Game Updated",
  RESPONSE_CSVUPLOADED: "Question list is created!",
  RESPONSE_QUESTIONLIST: "Question list found",
  ERROR_QUESTIONLIST: "Question list error",
  RESPONSE_QUESTIONCREATE: "Question Created!",
  RESPONSE_QUESTIONUPDATE: "Question updated",
  RESPONSE_QUESTIONDELETE: "Question Deleted",
  RESPONSE_SEASONLIST: "Season list found",
  RESPONSE_SEASONCREATE: "Season created successfully!",
  RESPONSE_SEASONUPDATE: "Season Updated",
  RESPONSE_SEASONDELETE: "Season Deleted",
  RESPONSE_EPISODELIST: "Episode list found",
  RESPONSE_EPISODEUPDATE: "Episode Updated",
  RESPONSE_EPISODEDELETE: "Episode Deleted",
  RESPONSE_EPISODECREATE: "Episode Created",
  RESPONSE_STACKLIST: "Stack list found",
  ERROR_STACKLIST: "Stack list error",
  RESPONSE_STACKCREATE: "Stack Created",
  RESPONSE_STACKUPDATE: "Stack Updated",
  RESPONSE_STACKDELETE: "Stack Deleted",
  RESPONSE_STACKFOUND: "stack search found",
  RESPONSE_STACK_QUESTION: "Stack Question found",
  RESPONSE_USERLIST: "User list found",
  RESPONSE_USERLISTAdd: "User created successfully.",
  RESPONSE_USERLISTUPDATE: "User Updated",
  RESPONSE_USERLISTDELETE: "User Deleted",
  RESPONSE_AppConfig: "App configuration found",
  RESPONSE_AppConfigCREATE: "appConfig Created",
  RESPONSE_AppConfigUPDATE: "appConfig Updated",
  RESPONSE_FETCHPRIZE: "Prize List Found",
  RESPONSE_CREATEPRIZE: "GamePrize Created Successfully",
  RESPONSE_UPDATEPRIZE: "GamePrize updated Successfully",
  RESPONSE_DELETEPRIZE: "GamePrize deleted Successfully",
  RESPONSE_FETCHREPORT: "User report found",
  RESPONSE_LINK: "Download CSV",
  QUESTION_LINK: "Download Questions",
  USER_LINK: "Download Users",
  SEARCH_FOUND: "search found",
  RESPONSE_AVATARLIST: "Avatar List Found",
  RESPONSE_AVATARLIST_DELETED: "Avatar deleted",
  RESPONSE_AVATARLIST_CREATED: "Avatar Created Successfully",
  RESPONSE_FETCH_PARAMETERS: "GameSetting found!",
  RESPONSE_UPDATED_PARAMETERS: "GameSetting successfully updated!",
  RESPONSE_TOKEN_EXPIRED: "jwt expired",
  RESPONSE_SINGLEQUESTION: "Single question found",
  END_POINT_REQUEST: "Endpoint request timed out",
  WRONG: "Something went wrong,Please try again.",
  RESPONSE_MANAGE_PUBLISH_WINNERLIST:"Winner list Publish",
  RESPONSE_WINNERLIST: "Winner list found",
  RESPONSE_WINNERLISTUPDATE: "Winner Updated",
  RESPONSE_WINNERLISTPUBLISH: "Winner Published",
  RESPONSE_WINNERPUBLISHMANAGE:"WinnerList found",
  RESPONSE_WINNERLISTADD:"Winners List Uploaded",
  RESPONSE_WINNERLISTDELETE:"Winners List Deleted",
  
  RESPONSE_SCHOOLNAMEANDCITY:"School Name and City found as per cpCustomerIds",

  RESPONSE_UPDATEDEFAULTPARAMETERS: "Defaul parameters updated",
  RESPONSE_BATCHDELETE: "Question Deleted",

  CSVUPLOADFAILED: "CSV UPLOAD FAILED",

  RESPONSE_SCHOOLLIST: "School list Found",
  RESPONSE_SCHOOLCREATE: "School Created",
  RESPONSE_SCHOOLUPDATE: "School Updated",
  RESPONSE_SCHOOLDELETE: "School Deleted",
  RESPONSE_SCHOOLDOWNLOAD: "School Download",
  RESPONSE_SCHOOLMERGE: "RESPONSE_SCHOOLMERGE",
  RESPONSE_PAYMENTLIST: "Payment list found",
  RESPONSE_PAYMENTLISTDOWNLOAD: "Payment Download",
  RESPONSE_FEEDBACKLIST: "Feedback list found",
  DOWNLOAD_FEEDBACKLIST: "Download Feedback ",

  RESPONSE_FAQCREATE: "FAQ Created",
  RESPONSE_FAQUPDATE: "FAQ Upadated",
  RESPONSE_FAQDELETE: "FAQ Deleted",
  RESPONSE_FAQLIST: "FAQ list Found",
};

export {
  AUTH,
  REPORT,
  DEFAULT_PARAMETERS,
  PRIZE,
  AVATAR,
  THEME,
  REFRESHDATA,
  SEASONLIST,
  WEBSOCKET,
  MESSAGES,
  ANALYTICS,
  NOTICEBOARD,
  BANNERS,
  MSG,
  GAMELIST,
  STACKLIST,
  QUESTIONLIST,
  COUNTS,
  EPISODELIST,
  USERLIST,
  APPCONFIG,
  WINNERLIST,
  SCHOOLLIST,
  FEEDBACK,
  PAYMENT,
  IMAGE_LIST,
  IMAGE_QUESTION,
  FAQLIST,
  GAMESTATUS,
  GAMEDISPLAY,
  USERCOUNT,
  OPTIONANALYTICS,
  ELIGIBILITYCHECK,
};
