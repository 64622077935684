export const IMAGE_LIST = {
  REQUEST_IMAGE_LIST: "REQUEST_IMAGE_LIST",
  SUCCESS_IMAGE_LIST: "SUCCESS_IMAGE_LIST",
  FAILURE_IMAGE_LIST: "FAILURE_IMAGE_LIST",
  SET_IMAGE_LIST: "SET_IMAGE_LIST",
};

export const IMAGE_QUESTION = {
  SET_IMAGE_QUESTION: "SET_IMAGE_QUESTION",
  REQUEST_IMAGE_QUESTION: "REQUEST_IMAGE_QUESTION",
  SUCCESS_IMAGE_QUESTION: "SUCCESS_IMAGE_QUESTION",
  FAILURE_IMAGE_QUESTION: "FAILURE_IMAGE_LIST",
};
