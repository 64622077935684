import { IMAGE_LIST } from "../constants/ImagesReduxConstants";

let initialState = {
  imageList: [],
  length: 0,
  isLoading: false,
  isError: false,
  isSuccess: false,
  selectedOptionType: 0,
  errorMSG: "",
  msg: "",
};

export default function reducer(state = initialState, action: any): typeof initialState {
  switch (action.type) {
    case IMAGE_LIST.REQUEST_IMAGE_LIST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };

    case IMAGE_LIST.SUCCESS_IMAGE_LIST:
      const data = action?.value?.data ?? [];
      const length = action?.value?.count ?? 0;
      const msg = action?.value?.msg ?? "";
      return {
        ...state,
        msg,
        length,
        isLoading: false,
        isError: false,
        imageList: data,
        isSuccess: true,
      };

    case IMAGE_LIST.FAILURE_IMAGE_LIST:
      const error = action?.value?.message ?? action?.value?.msg;
      const errorMSG = error ?? "Could not fetch Images";
      return {
        ...state,
        errorMSG,
        isError: true,
        isSuccess: false,
        isLoading: false,
      };

    case IMAGE_LIST.SET_IMAGE_LIST:
      console.log("Set Image List case", action);
      if (action?.key) {
        const key = action?.key;
        return {
          ...state,
          [key]: action?.value,
        };
      }
      return { ...state };

    default:
      return {
        ...state,
      };
  }
}
