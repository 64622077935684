import {
  AUTH,
  WEBSOCKET,
  DEFAULT_PARAMETERS,
  ANALYTICS,
  AVATAR,
  REPORT,
  PRIZE,
  NOTICEBOARD,
  APPCONFIG,
  USERLIST,
  EPISODELIST,
  BANNERS,
  SEASONLIST,
  MSG,
  GAMELIST,
  STACKLIST,
  QUESTIONLIST,
  COUNTS,
  WINNERLIST,
  SCHOOLLIST,
  FEEDBACK,
  PAYMENT,
  FAQLIST,
  GAMESTATUS,
  GAMEDISPLAY,
  USERCOUNT,
  OPTIONANALYTICS,
  ELIGIBILITYCHECK,
} from "../constants/constants";
// import { put, takeEvery, select } from 'redux-saga/effects';
// import { failure } from '../actions';
// import AppConfig from "../../configs/AppConfig";

export const token = (state: any) => state.adminReducer.token;

const requestLogin = (data: any) => ({
  type: AUTH.REQUEST_ADMIN_LOGIN,
  data,
});

const requestLogout = () => ({
  type: AUTH.REQUEST_ADMIN_LOGOUT,
});

const failure = (error: string) => ({
  type: AUTH.FAILURE_ADMIN_LOGIN,
  error,
});

const fetchAnalytics = (data: any) => ({
  type: ANALYTICS.REQUEST_FETCH_ANALYTICS,
  data,
});

const AvatarList = () => ({
  type: AVATAR.FETCH_AVATAR,
});

const createAvatar = (data: any) => ({
  type: AVATAR.CREATE_AVATAR,
  data,
});

const DeleteAvatar = (data: any) => ({
  type: AVATAR.DELETE_AVATAR,
  data,
});

const requestNoticeBoard = () => ({
  type: NOTICEBOARD.REQUEST_FETCH_NOTICEBOARD,
});

const createNoticeBoard = (data: any) => ({
  type: NOTICEBOARD.CREATE_NOTICEBOARD,
  data,
});

const updateNoticeBoard = (data: any) => ({
  type: NOTICEBOARD.UPDATE_NOTICEBOARD,
  data,
});

const deleteNoticeBoard = (data: any) => ({
  type: NOTICEBOARD.DELETE_NOTICEBOARD,
  data,
});

const requestBanner = () => ({
  type: BANNERS.REQUEST_FETCH_BANNERS,
});

const createBanner = (data: any) => ({
  type: BANNERS.CREATE_BANNERS,
  data,
});

const updateBanner = (data: any) => ({
  type: BANNERS.UPDATE_BANNERS,
  data,
});

const deleteBanner = (data: any) => ({
  type: BANNERS.DELETE_BANNERS,
  data,
});

const requestGameList = (data: any) => ({
  type: GAMELIST.REQUEST_FETCH_GAMELIST,
  data,
});

const updateGamePosition = (data: any) => ({
  type: GAMELIST.UPDATE_GAMEPOSITION,
  data,
});

const createGameList = (data: any) => ({
  type: GAMELIST.CREATE_GAMELIST,
  data,
});

const uploadCSV = (data: any) => ({
  type: QUESTIONLIST.UPLOAD_CSV,
  data,
});

const updateGameList = (data: any) => ({
  type: GAMELIST.UPDATE_GAMELIST,
  data,
});
const updateGameStatus = (data: any) => ({
  type: GAMESTATUS.UPDATE_GAMESTATUS,
  data,
});

const updateGameDisplay = (data: any) => ({
  type:GAMEDISPLAY.UPDATE_GAMEDISPLAY,
  data,
});

const updateOptionAnalytics = (data: any) => ({
  type:OPTIONANALYTICS.UPDATE_OPTIONANALYTICS, 
  data,
});

const updateGameEligibility = (data: any) => ({
  type:ELIGIBILITYCHECK.UPDATE_ELIGIBILITYCHECK, 
  data,
});



const updateUserCount = (data: any) => ({
  type:USERCOUNT.UPDATE_USERCOUNT,
  data,
});

const deleteGameList = (data: any) => ({
  type: GAMELIST.DELETE_GAMELIST,
  data,
});

const requestStackList = (data: any) => ({
  type: STACKLIST.REQUEST_FETCH_STACKLIST,
  data,
});

const createStackList = (data: any) => ({
  type: STACKLIST.CREATE_STACKLIST,
  data,
});

const updateStackList = (data: any) => ({
  type: STACKLIST.UPDATE_STACKLIST,
  data,
});

const fetchStackQuestions = (data: any) => ({
  type: STACKLIST.FETCH_STACK_QUESTION,
  data,
});

const deleteStackList = (data: any) => ({
  type: STACKLIST.DELETE_STACKLIST,
  data,
});

const requestQuestionList = (data: any) => ({
  type: QUESTIONLIST.REQUEST_FETCH_QUESTIONLIST,
  data,
});

const createQuestionList = (data: any) => ({
  type: QUESTIONLIST.CREATE_QUESTIONLIST,
  data,
});

const updateQuestionList = (data: any) => ({
  type: QUESTIONLIST.UPDATE_QUESTIONLIST,
  data,
});

const deleteQuestionList = (data: any) => ({
  type: QUESTIONLIST.DELETE_QUESTIONLIST,
  data,
});

const deleteMUltipleQuestionList = (data: any) => ({
  type: QUESTIONLIST.DELETE_MULTIPLEQUESTIONLIST,
  data,
});

const deletebatchquestionlist = (data: any) => ({
  type: QUESTIONLIST.DELETE_BATCHQUESTIONS,
  data,
});

const DeleteMultipleQuestions = (data: any) => ({
  type: QUESTIONLIST.DELETE_MULTIPLEQUESTIONS,
  data,
});

const fetchDownloadUserReport = (data: any) => ({
  type: REPORT.FETCHDOWNLOADUSERREPORT,
  data,
});

const downloadAllUsers = () => ({
  type: REPORT.DOWNLOADUSERS,
});

const requestCounts = () => ({
  type: COUNTS.REQUEST_FETCH_COUNTS,
});

const clearMsg = () => ({
  type: MSG.CLEAR_MSG,
});

const requestSeason = () => ({
  type: SEASONLIST.REQUEST_FETCH_SEASONLIST,
});

const createSeason = (data: any) => ({
  type: SEASONLIST.CREATE_SEASON,
  data,
});

const updateSeason = (data: any) => ({
  type: SEASONLIST.UPDATE_SEASON,
  data,
});

const deleteSeason = (data: any) => ({
  type: SEASONLIST.DELETE_SEASON,
  data,
});

const requestEpisode = () => ({
  type: EPISODELIST.REQUEST_FETCH_EPISODE,
});

const createEpisode = (data: any) => ({
  type: EPISODELIST.CREATE_EPISODE,
  data,
});

const updateEpisode = (data: any) => ({
  type: EPISODELIST.UPDATE_EPISODE,
  data,
});

const deleteEpisode = (data: any) => ({
  type: EPISODELIST.DELETE_EPISODE,
  data,
});

const requestUserList = (data: any) => ({
  type: USERLIST.REQUEST_FETCH_USERLIST,
  data,
});

const addUser = (data: any) => ({
  type: USERLIST.CREATE_USERLIST,
  data,
});

const updateUserList = (data: any) => ({
  type: USERLIST.UPDATE_USERLIST,
  data,
});

const deleteUserList = (data: any) => ({
  type: USERLIST.DELETE_USERLIST,
  data,
});

const requestAppConfig = () => ({
  type: APPCONFIG.REQUEST_FETCH_APPCONFIG,
});

const createAppConfig = (data: any) => ({
  type: APPCONFIG.CREATE_APPCONFIG,
  data,
});

const updateAppConfig = (data: any) => ({
  type: APPCONFIG.DELETE_APPCONFIG,
  data,
});

const deleteAppConfig = (data: any) => ({
  type: APPCONFIG.DELETE_APPCONFIG,
  data,
});

const createPrize = (data: any) => ({
  type: PRIZE.CREATE_PRIZE,
  data,
});

const fetchPrize = () => ({
  type: PRIZE.REQUEST_FETCH_PRIZE,
});

const UpdatePrize = (data: any) => ({
  type: PRIZE.UPDATE_PRIZE,
  data,
});

const DeletePrize = (data: any) => ({
  type: PRIZE.DELETE_PRIZE,
  data,
});

const fetchUserReport = (data: any) => ({
  type: REPORT.FETCH_REPORT,
  data,
});

const fetchDefaultPoints = () => ({
  type: DEFAULT_PARAMETERS.FETCH_PARAMETERS,
});

const updateDefaultPoints = (data: any) => ({
  type: DEFAULT_PARAMETERS.UPDATE_PARAMETERS,
  data,
});

const deleteUserHistory = (data: any) => ({
  type: REPORT.DELETEUSERHISTORY,
  data,
});

const DownloadAllQuestions = (data: any) => ({
  type: QUESTIONLIST.DOWNLOAD_QUESTION,
  data,
});

const DeleteQuestionList = () => ({
  type: QUESTIONLIST.NULLQUESTIONLIST,
});

const disconnectedAction = () => ({
  type: WEBSOCKET.WS_DISCONNECTED,
  value: null,
});

const SingleQuestionObj = (data: any) => ({
  type: QUESTIONLIST.SINGLE,
  data,
});

const fetchSingleQuestion = (data: any) => ({
  type: QUESTIONLIST.REQUEST_FETCH_SINGLEQUESTION,
  data,
});


const addWinnerList = (data:any) => ({
  type: WINNERLIST.ADD_WINNERLIST,
  data
});

const WinnerPublish = (data:any) => ({
  type: WINNERLIST.WINNER_PUBLISH,
  data
});
const ManagePublishWinnerList = (data: any) => ({
  type: WINNERLIST.MANAGE_PUBLISH_WINNERLIST,
  data,
});
const fetchWinnerList = (data: any) => ({
  type: WINNERLIST.REQUEST_FETCH_WINNERLIST,
  data,
});

const updateWinnerList = (data: any) => ({
  type: WINNERLIST.UPDATE_WINNERLIST,
  data,
});

const publishWinnerList = (data: any) => ({
  type: WINNERLIST.PUBLISH_WINNERLIST,
  data,
});

const deleteWinnerList = (data:any) => ({
  type:WINNERLIST.DELETE_WINNERLIST,
  data
})
const fetchSchoolList = (data: any) => ({
  type: SCHOOLLIST.REQUEST_FETCH_SCHOOLLIST,
  data,
});

const fetchFAQList = (data: any) => ({
  type: FAQLIST.REQUEST_FETCH_FAQLIST,
  data,
});

const createSchoolList = (data: any) => ({
  type: SCHOOLLIST.CREATE_SCHOOLLIST,
  data,
});

const createFAQList = (data: any) => ({
  type: FAQLIST.CREATE_FAQLIST,
  data,
});

const updateFAQList = (data: any) => ({
  type: FAQLIST.UPDATE_FAQLIST,
  data,
});

const deleteFAQList = (data: any) => ({
  type: FAQLIST.DELETE_FAQLIST,
  data,
});

const updateSchoolList = (data: any) => ({
  type: SCHOOLLIST.UPDATE_SCHOOLLIST,
  data,
});

const deleteSchoolList = (data: any) => ({
  type: SCHOOLLIST.DELETE_SCHOOLLIST,
  data,
});

const feedbacklist = (data: any) => ({
  type: FEEDBACK.REQUEST_FETCH_FEEDBACK,
  data,
});

const feedbackdownload = () => ({
  type: FEEDBACK.DOWNLOAD_FEEDBACK,
});

const schoollistdownload = (data: any) => ({
  type: SCHOOLLIST.DOWNLOAD_SCHOOLLIST,
  data,
});

const mergeschoollist = (data: any) => ({
  type: SCHOOLLIST.MERGE_SCHOOLLIST,
  data,
});

const paymentlist = (data: any) => ({
  type: PAYMENT.REQUEST_FETCH_PAYMENT,
  data,
});

const paymentlistdownload = (data: any) => ({
  type: PAYMENT.DOWNLOAD_PAYMENT,
  data,
});

export function setConnectedAction(connected: boolean) {
  return {
    type: WEBSOCKET.SET_CONNECTED,
    value: connected,
  };
}

export {
  requestLogin,
  requestLogout,
  failure,
  clearMsg,
  fetchAnalytics,
  requestNoticeBoard,
  updateNoticeBoard,
  createNoticeBoard,
  deleteNoticeBoard,
  updateBanner,
  createBanner,
  deleteBanner,
  requestBanner,
  requestGameList,
  createGameList,
  updateGameList,
  updateGameStatus,
  updateGameDisplay,
  updateOptionAnalytics,
  updateGameEligibility,
  updateUserCount,
  deleteGameList,
  requestStackList,
  createStackList,
  updateStackList,
  deleteStackList,
  requestQuestionList,
  createQuestionList,
  updateQuestionList,
  deleteQuestionList,
  requestCounts,
  uploadCSV,
  requestSeason,
  createSeason,
  updateSeason,
  deleteSeason,
  createEpisode,
  updateEpisode,
  requestEpisode,
  deleteEpisode,
  addUser,
  updateUserList,
  requestUserList,
  deleteUserList,
  requestAppConfig,
  updateAppConfig,
  createAppConfig,
  deleteAppConfig,
  createPrize,
  fetchPrize,
  UpdatePrize,
  DeletePrize,
  fetchUserReport,
  DeleteMultipleQuestions,
  fetchDownloadUserReport,
  deleteUserHistory,
  disconnectedAction,
  DownloadAllQuestions,
  AvatarList,
  createAvatar,
  DeleteAvatar,
  fetchDefaultPoints,
  updateDefaultPoints,
  fetchStackQuestions,
  downloadAllUsers,
  DeleteQuestionList,
  SingleQuestionObj,
  fetchSingleQuestion,
  deleteMUltipleQuestionList,
  deletebatchquestionlist,
  addWinnerList,
  WinnerPublish,
  fetchWinnerList,
  updateWinnerList,
  publishWinnerList,
  ManagePublishWinnerList,
  deleteWinnerList,
  fetchSchoolList,
  createSchoolList,
  updateSchoolList,
  deleteSchoolList,
  feedbacklist,
  feedbackdownload,
  schoollistdownload,
  mergeschoollist,
  paymentlist,
  paymentlistdownload,
  updateGamePosition,
  createFAQList,
  fetchFAQList,
  updateFAQList,
  deleteFAQList,
};
