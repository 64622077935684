import React from "react";
import async from "../components/Async";
import {
  Activity,
  BookOpen,
  Briefcase,
  Delete,
  HardDrive,
  Monitor,
  Users,
  Settings,
  User,
  Image,
} from "react-feather";
import { MenuIcon } from "@material-ui/data-grid";
import { IoSchoolOutline } from "react-icons/io5";
import { MdPayment } from "react-icons/md";
import { useSelector } from "react-redux";
import { Role } from "../role-types";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const Analytics = async(() => import("../pages/analytics"));
const Game = async(() => import("../pages/game/index"));
const ManageGame1 = async(() => import("../pages/game/gameManager"));
const GameEdit = async(() => import("../pages/game/gameEdit"));
const ManageDefaultPoints = async(() => import("../pages/game/defaultPoints"));
const userHistory = async(() => import("../pages/users/userHistory"));
const winnerHistory = async(()=>import("../pages/winners/winnersHistory"));
const AddWinner = async(()=>import("../pages/winners/addWinner"));
const WinnerPublish = async(()=>import("../pages/winners/winnerPublishManage"));
const GenerateInvitationLinks = async(()=>import("../pages/School/GenerateInvitationLinks"));
// const CreateSeasons = async(() => import("../pages/Rules/index"));
// const ManageSeasons = async(() => import("../pages/Rules/manageSeaons"));
// const EditSeasons = async(() => import("../pages/Rules/editSeason"));
// const CreateEpisode = async(() => import("../pages/episodes/index"));
const EditEpisode = async(() => import("../pages/episodes/editEpisodes"));
// const ManageEpisode = async(() => import("../pages/episodes/manageEpisode"));
const CreateStack = async(() => import("../pages/stacks/index"));
const EditStack = async(() => import("../pages/stacks/editStack"));
const ManageStack = async(() => import("../pages/stacks/manageStack"));
const CreateQuestion = async(() => import("../pages/questions/index"));
const EditQuestion = async(() => import("../pages/questions/editQuestion"));
const EditStackQuestion = async(() => import("../pages/questions/editStackQuestion"));
const ManageQuestion = async(() => import("../pages/questions/newmanageQuestion"));
const UploadCSV = async(() => import("../pages/questions/importCSV"));
const ManageUsers = async(() => import("../pages/users/index"));
const ManageBanner = async(() => import("../pages/board/managebanner"));
const CreateBanner = async(() => import("../pages/board/addBanner"));
const EditBanner = async(() => import("../pages/board/editBanner"));
const ManageNoticeBoard = async(() => import("../pages/board/manageNoticeBoard"));
const CreateNotice = async(() => import("../pages/board/addNoticeBoard"));
const EditNotice = async(() => import("../pages/board/editNoticeBoard"));
const gameStepper = async(() => import("../pages/stepper/index"));
// const createPrize = async(() => import("../pages/price/index"));
// const editPrize = async(() => import("../pages/price/edit"));
// const fetchPrize = async(() => import("../pages/price/fetch"));
const appSettings = async(() => import("../pages/settings/index"));
const DeleteHistory = async(() => import("../pages/users/deleteHistory"));
const avtars = async(() => import("../pages/avatars/index"));
const batchUpload = async(() => import("../pages/BatchUpload/index"));
// const showImage = async(() => import("../pages/BatchUpload/showImage"));
const edituser = async(() => import("../pages/users/editUsers"));
const Batchdelete = async(() => import("../pages/questions/batchdeletequestion"));
const gameHistory = async(() => import("../pages/users/gamehistory"));
const createschool = async(() => import("../pages/School/CreateSchool"));
const ManageSchool = async(() => import("../pages/School/ManageSchool"));
const EditSchool = async(() => import("../pages/School/EditSchool"));
const Feedback = async(() => import("../pages/feedback/Feedback"));
const MergeSchool = async(() => import("../pages/School/MergeSchool"));
const Adminusers = async(() => import("../pages/users/Manageadminuserlist"));
const AddUser = async(() => import("../pages/users/addUsers"));
const Managepayment = async(() => import("../pages/payment/Managepayment"));
const TermsAndConditon = async(()=> import("../pages/tnc/addTnc"))
const faq = async(()=> import("../pages/faq/addFAQ"))
const Createfaq = async(()=> import("../pages/faq/createFAQ"))
const Managefaq = async(()=> import("../pages/faq/manageFAQ"))
const Editfaq = async(()=> import("../pages/faq/editFAQ"))
const about = async(()=> import("../pages/aboutUs/AddAboutus"))
const contentFormat = async(()=> import("../pages/ContentFormat/addContent"))
const addTnc = async(()=> import("../pages/tnc/addTnc"))
const UploadImageInS3 = async(() => import("../pages/BatchUpload/UploadImageInS3"))
const Maintenance = async(()=> import("../pages/maintenance/index"))

// // @ts-ignore
// const rolesData = useSelector((state) => state.adminReducer.rolesData);
// console.log("rolesData", rolesData);

const MaintenanceRoutes = {
  id: "Maintenance",
  path: "/maintenance",
  icon: <Settings />,
  component: Maintenance,
  children: null,
  containsHome: false,
};

const GameEditRoutes = {
  id: "Games",
  path: "/game/GameEdit",
  component: GameEdit,
  children: null,
  Permissions: [Role.SuperUser],
};

const QuestionEditRoutes = {
  id: "Games",
  path: "/questions/editQuestion",
  component: EditQuestion,
  children: null,
};

const EditStackQuestionRoutes = {
  id: "Games",
  path: "/questions/editStackQuestion",
  component: EditStackQuestion,
  children: null,
};

// const SeasonEditRoutes = {
//   id: "Rules & Prize",
//   path: "/rules/editRules",
//   component: EditSeasons,
//   children: null,
// }

const EpisodeEditRoutes = {
  id: "Episodes",
  path: "/epsiodes/editEpisode",
  component: EditEpisode,
  children: null,
};

const StackEditRoutes = {
  id: "Stacks",
  path: "/stack/editStack",
  component: EditStack,
  children: null,
};

const UserEditRoutes = {
  id: "Users",
  path: "/users/manage/edit",
  component: edituser,
  children: null,
};

const AddUserRoutes = {
  id: "Users",
  path: "/users/manage/add",
  component: AddUser,
  children: null,
};

const SchoolEditRoutes = {
  id: "Schools",
  path: "/school/manage/edit",
  component: EditSchool,
  children: null,
};

const MergeSchoolRoutes = {
  id: "Schools",
  path: "/school/manage/merge",
  component: MergeSchool,
  children: null,
};

const EditNoticeRoutes = {
  id: "Board",
  path: "/board/editNotice",
  component: EditNotice,
  children: null,
};

const EditBannerRoutes = {
  id: "Board",
  path: "/board/editBanner",
  component: EditBanner,
  children: null,
};

const FaqEditRoutes = {
  id: "Faq",
  path: "/faq/manage/edit",
  component: Editfaq,
  children: null,
};

// const EditPrizeRoutes = {
//   id: "Prize",
//   path: "/prize/editPrize",
//   component: editPrize,
//   children: null,
// }

// const PriceRoutes = {
//   id: "Prize",
//   path: "/Prize",
//   header: "Prize",
//   icon: <Gift />,
//   containsHome: false,
//   children: [
//     {
//       path: "/prize/create",
//       name: "create Prize",
//       component: createPrize
//     },
//     {
//       path: "/prize/list",
//       name: "Prize List",
//       component: fetchPrize
//     },
//   ],
//   component: null
// }

const AppConfigRoutes = {
  id: "App Config",
  path: "/config",
  icon: <Settings />,
  containsHome: false,
  component: appSettings,
  children: null,
};

const UploadImageS3 = {
  id: "Media Upload",
  path: "/UploadImage",
  component: UploadImageInS3,
  children: null,
}
const BatchUpload = {
  id: "Media Center",
  path: "/batchImage",
  header: "Media Center",
  icon: <Image />,
  containsHome: false,
  component: batchUpload,
  children: null,
  // children: [
  //   {
  //     path: "/batchImage/upload",
  //     name: "Batch Upload",
  //     component: batchUpload
  //   },
  //   {
  //     path: "/batchImage/show",
  //     name: "Show Image",
  //     component: showImage
  //   },
  // ],
  // component: null
};
const TermsAndConditonRoute = {
  id: "Terms and conditions ",
  path: "/tnc",
  icon: <User />,
  // icon: <TermsAndConditon/>,
  containsHome: false,
  children:  [
    {
      path: "/tnc/create",
      name: "Manage TNC",
      component: addTnc,
    },
    /* {
      path: "/tnc/manage",
      name: "Manage TNC",
      component: TermsAndConditon,
    }, */
  ],
  component: null,
};
const FAQ = {
  id: "FAQ",
  path: "/faq",
  icon: <User />,
  containsHome: false,
  children:  [
    // {
    //   path: "/faq/addFAQ",
    //   name: "Second FAQ",
    //   component: faq,
    // },
    {
      path: "/faq/createFAQ",
      name: "Create FAQ",
      component: Createfaq,
    },
    {
      path: "/faq/manage",
      name: "Manage FAQ",
      component: Managefaq,
    },
  ],
  component: null,
};

const ContentFormat = {
  id: "Contest Format",
  path: "/ContentFormat",
  icon: <User />,
  containsHome: false,
  children:  [
    {
      path: "/ContentFormat/addContent",
      name: "Manage Contest",
      component: contentFormat,
    },
  ],
  component: null,
};

const AboutUs = {
  id: "About Us",
  path: "/aboutus",
  icon: <User />,
  containsHome: false,
  children:  [
    {
      path: "/aboutUs/addAboutus",
      name: "Manage About Us",
      component: about,
    },
  ],
  component: null,
};

const AppAvtar = {
  id: "Avatars",
  path: "/avatars",
  icon: <User />,
  containsHome: false,
  component: avtars,
  children: null,
};

const AppPayment = {
  id: "Payments",
  path: "/payment",
  icon: <MdPayment />,
  containsHome: false,
  component: Managepayment,
  children: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};
const analyticsRoutes = {
  id: "Analytics",
  path: "/",
  header: "Analytics",
  icon: <Monitor />,
  component: Analytics,
  children: null,
};

const schoolRoutes = {
  id: "School",
  path: "/school",
  header: "School",
  icon: <IoSchoolOutline />,
  containsHome: false,
  children: [
    {
      path: "/school/create",
      name: "Create School",
      component: createschool,
    },
    {
      path: "/school/manage",
      name: "Manage School",
      component: ManageSchool,
    },
    {
      path: "/school/generateInviteUrls",
      name: "Generate Invitation Links",
      component: GenerateInvitationLinks,
    }
  ],
  component: null,
};


const gameRoutes = {
  id: "Game",
  path: "/game",
  header: "Game",
  icon: <MenuIcon />,
  containsHome: false,
  children: [
    {
      path: "/game/create",
      name: "Create Game",
      component: Game,
    },
    {
      path: "/game/managegame",
      name: "Manage Games",
      component: ManageGame1,
    },
    {
      path: "/game/managePoints",
      name: "Manage Default Parameters",
      component: ManageDefaultPoints,
    },
  ],
  component: null,
};
const winnersRoutes = {
  id: "Winners",
  path: "/winners",
  header: "Winners History",
  icon: <Users />,
  containsHome: false,
  children: [
    // {
    //   path: "/winners/history",
    //   name: "Winners History",
    //   component: winnerHistory,
    // },
    {
      path: "/winners/addWinner",
      name: "Add Winners",
      component: AddWinner,
    },
    {
      path: "/winners/winnerpublish",
      name: "Winner Publish",
      component: WinnerPublish,
    },
  ],
  component: null,
}
// const gameWizard = {
//   id: "wizard",
//   path: "/gamewizard",
//   header: "wizard",
//   icon: <HardDrive />,
//   component: gameStepper,
//   children: null,
// };
// const seasonsRoutes = {
//   id: "Rules & Prize",
//   path: "/rules",
//   header: "Rules & Prize",
//   icon: <Folder />,
//   containsHome: false,
//   children: [
//     {
//       path: "/rules/create",
//       name: "Create Rules & Prize",
//       component: CreateSeasons
//     },
//     {
//       path: "/rules/manage",
//       name: "Manage Rules & Prize",
//       component: ManageSeasons
//     }
//   ],
//   component: null
// };
// const episodesRoutes = {
//   id: "Episodes",
//   path: "/episodes",
//   header: "Episodes",
//   icon: <FileMinus />,
//   containsHome: false,
//   children: [
//     {
//       path: "/episodes/create",
//       name: "Create Episode",
//       component: CreateEpisode
//     },
//     {
//       path: "/episodes/manage",
//       name: "Manage Episodes",
//       component: ManageEpisode
//     }
//   ],
//   component: null
// };
const stacksRoutes = {
  id: "Stacks",
  path: "/stacks",
  header: "Stacks",
  icon: <Activity />,
  containsHome: false,
  children: [
    {
      path: "/stacks/create",
      name: "Create Stack",
      component: CreateStack,
    },
    {
      path: "/stacks/manage",
      name: "Manage Stacks",
      component: ManageStack,
    },
  ],
  component: null,
};
const questionsRoutes = {
  id: "Questions",
  path: "/questions",
  header: "Questions",
  icon: <BookOpen />,
  containsHome: false,
  children: [
    {
      path: "/questions/create",
      name: "Create Question",
      component: CreateQuestion,
    },
    {
      path: "/questions/upload",
      name: "Upload CSV",
      component: UploadCSV,
    },
    {
      path: "/questions/list",
      name: "Manage Questions",
      component: ManageQuestion,
    },
    {
      path: "/questions/batchdelete",
      name: "Batch Delete Questions",
      component: Batchdelete,
    },
  ],
  component: null,
};


const usersRoutes = {
  id: "Users",
  path: "/users",
  header: "Users",
  icon: <Users />,
  containsHome: false,
  children: [
    {
      path: "/users/manage",
      name: "Manage Users",
      component: ManageUsers,
    },
    // {
    //   path: "/users/admin",
    //   name: "Admin Users",
    //   component: Adminusers,
    // },
    {
      path: "/users/create",
      name: "Create User",
      component: AddUser,
    },
  ],
  component: null,
};

// const usersHistory = {
//   id: "Users",
//   path: "/users/history",
//   header: "Users History",
//   icon: <Users />,
//   containsHome: false,
//   name: "User History",
//   component: userHistory
// };

// const usersHistory = {
//   id: "Winners History",
//   path: "/history",
//   icon: <Users />,
//   component: userHistory,
//   children: null,
// };

const gamesHistory = {
  id: "Game History",
  path: "/game/history",
  icon: <Users />,
  component: gameHistory,
  children: null,
};

const historyDelete = {
  id: "Delete User History",
  path: "/DeleteHistory",
  icon: <Delete />,
  component: DeleteHistory,
  children: null,
};

const feedbacklist = {
  id: "Feedback",
  path: "/feedback",
  icon: <Delete />,
  component: Feedback,
  children: null,
};

const BoardRoutes = {
  id: "Board",
  path: "/board",
  header: "board",
  icon: <Briefcase />,
  containsHome: false,
  children: [
    {
      path: "/board/createNotice",
      name: "Add Notice",
      component: CreateNotice,
    },
    {
      path: "/board/ManageNotice",
      name: "Manage Notice",
      component: ManageNoticeBoard,
    },
    {
      path: "/board/createBanner",
      name: "Add Banner",
      component: CreateBanner,
    },
    {
      path: "/board/ManageBanner",
      name: "Manage Banner",
      component: ManageBanner,
    },
  ],
  component: null,
};

// This route is not visible in the sidebar
const privateRoutes = {
  id: "Private",
  path: "/private",
  component: Blank,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  analyticsRoutes,
  // gamesRoutes,
  gameRoutes,
  // seasonsRoutes,
  // episodesRoutes,
  stacksRoutes,
  questionsRoutes,
  usersRoutes,
  privateRoutes,
  GameEditRoutes,
  BoardRoutes,
  schoolRoutes,
  QuestionEditRoutes,
  EditStackQuestionRoutes,
  // SeasonEditRoutes,
  EpisodeEditRoutes,
  // gameWizard,
  StackEditRoutes,
  UserEditRoutes,
  SchoolEditRoutes,
  EditNoticeRoutes,
  EditBannerRoutes,
  AddUserRoutes,
  winnersRoutes,
  // PriceRoutes,
  // EditPrizeRoutes,
  AppPayment,
  gamesHistory,
  historyDelete,
  feedbacklist,
  schoolRoutes,
  AppConfigRoutes,
  AppAvtar,
  UploadImageS3,
  BatchUpload,
  MergeSchoolRoutes,
  TermsAndConditonRoute,
  ContentFormat,
  MaintenanceRoutes,
  AboutUs,
  FAQ,
  FaqEditRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  // gameWizard,
  analyticsRoutes,
  // gamesRoutes,
  gameRoutes,
  // seasonsRoutes,
  // episodesRoutes,
  stacksRoutes,
  questionsRoutes,
  // SeasonEditRoutes,
  // EditPrizeRoutes,
  usersRoutes,
  BoardRoutes,
  schoolRoutes,
  winnersRoutes,
  AppPayment,
  EpisodeEditRoutes,
  StackEditRoutes,
  EditNoticeRoutes,
  EditBannerRoutes,
  // PriceRoutes,
  MaintenanceRoutes,
  gamesHistory,

  // historyDelete,
  feedbacklist,
  AppConfigRoutes,
  AppAvtar,
  BatchUpload,
  TermsAndConditonRoute,
  ContentFormat,
  AboutUs,
  FAQ,
  FaqEditRoutes,
];
