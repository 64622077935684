import { combineReducers } from "redux";

import themeReducer from "./themeReducers";
import adminReducer from "./adminReducers";
import imageReducer from "./imageReducers";
import imageQuestionReducer from "./imageQuestionReducer";

export const rootReducer = combineReducers({
  themeReducer,
  adminReducer,
  imageReducer,
  imageQuestionReducer,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;
