import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import { composeWithDevTools } from "redux-devtools-extension";

// @ts-ignore
import logger from "redux-logger";
import { rootReducer } from "../reducers";
const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    //@ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? compose(
          //@ts-ignore
          applyMiddleware(sagaMiddleware, logger),
          //@ts-ignore
          window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      : //@ts-ignore
        applyMiddleware(sagaMiddleware, logger)
  );
  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureStore;
