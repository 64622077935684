import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { GlobalStyleProps, RouteInfoType } from '../types/types'
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Settings from "../components/Settings";

import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";
import {requestLogin} from "../redux/actions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${props => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type DashboardPropsType = {
  routes: Array<RouteInfoType>
  width: "md" | "xs" | "sm" | "lg" | "xl"
}

const Dashboard = (props:any) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if(!props.isLoggedIn){
    return <Redirect to={"/auth/sign-in"}/>
  }

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={props.routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar
            routes={props.routes}
            PaperProps={{ style: { width: drawerWidth } }}
          />
        </Hidden>
      </Drawer>
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isWidthUp("lg", props.width) ? 10 : 5}>
          {props.children}
        </MainContent>
        <Footer />
      </AppContent>
      <Settings />
    </Root>
  )
}
const mapStateToProps = (state:any) => {
  return {
    isLoggedIn : state.adminReducer.isLoggedIn
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  requestLogin: (data: any) => dispatch(requestLogin(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(Dashboard));

