import { IMAGE_QUESTION } from "../constants/ImagesReduxConstants";

let initialState = {
  question: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMSG: "",
  msg: "",
};

export default function reducer(state = initialState, action: any): typeof initialState {
  switch (action.type) {
    case IMAGE_QUESTION.SET_IMAGE_QUESTION:
      return initialState;
    case IMAGE_QUESTION.REQUEST_IMAGE_QUESTION:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };

    case IMAGE_QUESTION.SUCCESS_IMAGE_QUESTION:
      const data = action?.value?.data ?? [];
      const msg = action?.value?.msg ?? "";
      return {
        ...state,
        msg,
        isLoading: false,
        isError: false,
        question: data,
        isSuccess: true,
      };

    case IMAGE_QUESTION.FAILURE_IMAGE_QUESTION:
      const error = action?.value?.message ?? action?.value?.msg;
      const errorMSG = error ?? "Could not fetch question";
      return {
        ...state,
        errorMSG,
        isLoading: false,
        isError: true,
        isSuccess: false,
      };

    default:
      return {
        ...state,
      };
  }
}
