import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { Helmet } from 'react-helmet';

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";

import maTheme from "./theme";
import Routes from "./routes/Routes";
import { AppStateType } from "./redux/reducers";
import "./App.css";
import jwtDecode from 'jwt-decode';
import { requestLogout } from "./redux/actions";

function App(props: any) {
  const dispatch = useDispatch();
  var dateNow = new Date();
  // @ts-ignore
  const token = useSelector(state => state.adminReducer.token);
  var decoded = token ? jwtDecode(token) : null
  console.log("decode", decoded)
  console.log("gegtTiemdecode", dateNow.getTime())
  console.log("decoded", decoded)
  if (decoded) {
    // @ts-ignore
    if (decoded.exp * 1000 < dateNow.getTime()) {
      console.log("helllo broght")
      dispatch(requestLogout())
    }
    // @ts-ignore

  }

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Digitok"
        defaultTitle="Digitok Admin Panel"
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[props.theme.currentTheme]}>
            <ThemeProvider theme={maTheme[props.theme.currentTheme]}>
              <Routes adminReducer={props.admin} />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default connect((store: AppStateType) => ({ theme: store.themeReducer, admin: store.adminReducer }))(App);
