import jwtDecode from "jwt-decode";
import * as types from "../constants/constants";
const CryptoJS = require("crypto-js");
const SECRET_KEY = "secureMyData";
const SecureStorage = require("secure-web-storage");

export var secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key: any) {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt: function encrypt(data: any) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data: any) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  },
});

let initialState = {
  analytics: {
    QuizNo: 0,
    totalUsersInThisWeek: 0,
    totalUsersInLastWeek: 0,
    totalQuestions: 0,
    totalQuestionsInLastWeek: 0,
    totalQuizzesToday: 0,
    totalQuizzesYesterday: 0,
    totalQuizzesInWeek: 0,
    totalQuizzesInLastWeek: 0,
    quizLogs: [],
    combinedDaily: [],
    combinedWeekly: [],
  },
  noticeBoard: [],
  bannerList: [],
  gameList: [],
  schoollist: [],
  feedbacklist: [],
  gameCount: 0,
  QuestionObj: {},
  userCount: 0,
  userListSearchKeyword: "",
  userListPage: 1,
  userList: [],
  prizeList: [],
  avatarList: [],
  paymentlist: [],
  stackQuestion: [],
  isLoading: true,
  msg: null,
  skipWeek:[],
  skipDate:[],
  userReportList: [],
  TotalQuestion: 0,
  TotalUser: 0,
  questionSearchKeyword: "",
  questionPage: 1,
  questionList: [],
  questionFilters: null,
  questionCount: 0,
  seasonList: [],
  episodeList: [],
  stackSearchKeyword: "",
  stackPage: 1,
  stackList: [],
  stackCount: 0,
  usermsg: "",
  allCount: {},
  DefaultPoints: {},
  link: "",
  WSCONNECTED: false,
  errMsg: "",
  error: "",
  QuestionLink: "",
  UserLink: "",
  admin: secureStorage.getItem("admin") ? secureStorage.getItem("admin") : {},
  token: secureStorage.getItem("token") ? secureStorage.getItem("token") : null,
  isLoggedIn: secureStorage.getItem("isLoggedIn") ? secureStorage.getItem("isLoggedIn") : false,
  rememberMe: secureStorage.getItem("rememberMe") ? secureStorage.getItem("rememberMe") : false,
  loading: false,
  rolesData: {},
  winnerlist: [],
  schoollistdata:{},
  winnerPublishManage:[],
  winnerAddApiDATA:{},
  ResponseManageWinnerPublish:{},
};

export default function reducer(state = initialState, actions: any): any {
  console.log("stategamelist", state.gameList);
  console.log("state", state);
  console.log("actions", actions);
  switch (actions.type) {
    case types.AUTH.REMEMBER_ME:
      secureStorage.setItem("rememberMe", actions.value.rememberMe);
      return {
        ...state,
        rememberMe: actions.value.rememberMe,
        loading: true,
      };

    case types.MESSAGES.LOGIN_SUCCESS:
      console.log("jwtDecode", jwtDecode(actions.value.data.authToken));
      secureStorage.setItem("token", actions.value.data.authToken);
      if (state.rememberMe) {
        secureStorage.setItem("isLoggedIn", String(!!actions.value.data.authToken));
        secureStorage.setItem("admin", actions.value.data);
        secureStorage.setItem("token", actions.value.data.authToken);
        secureStorage.setItem("userId",actions.value.data.userData.id);
      }
      return {
        ...state,
        admin: actions.value.data,
        rolesData: jwtDecode(actions.value.data.authToken),
        token: actions.value.data.authToken,
        isLoggedIn: !!actions.value.data.authToken,
        loading: false,
      };

    case types.MESSAGES.DIGITOK_ANALYTICS:
      console.log("Actions", actions);
      return {
        ...state,
        analytics: actions.value.data,
      };

    case types.MESSAGES.RESPONSE_FETCHREPORT:
      return {
        ...state,
        msg: actions.value.msg,
        userReportList: actions.value.data,
        lowerQuizNo: actions?.value?.lowerQuizNo,
        upperQuizNo: actions?.value?.upperQuizNo,
        isAppointment: actions?.value?.isAppointment,
      };

    case types.MESSAGES.RESPONSE_NOTICEBOARD:
      return {
        ...state,
        noticeBoard: actions.value.data,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_FETCH_PARAMETERS:
      return {
        ...state,
        msg: actions.value.msg,
        DefaultPoints: actions.value.data,
      };

    case types.MESSAGES.RESPONSE_UPDATED_PARAMETERS:
      return {
        ...state,
        DefaultPoints: actions.value.data,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_BANNERS:
      return {
        ...state,
        bannerList: actions.value.data,
        msg: actions.value.msg,
      };

    case types.MESSAGES.PASSWORD_WRONG:
      return {
        ...state,
        errMsg: actions.value.msg,
        loading: false,
      };
    case types.MESSAGES.ERROR:
      console.log("sdfds", actions.error);

      return {
        ...state,
        errMsg: actions.error,
        status: actions.status,
        loading: false,
      };

    case types.MESSAGES.CSVUPLOADFAILED:
      console.log("CSVUPLOADFAILED", actions?.value?.response?.data?.msg);

      return {
        ...state,
        errMsg: actions?.value?.response?.data?.msg,
        loading: false,
      };

    case types.MESSAGES.RESPONSE_GAMELIST:
      console.log("action,", actions);
      var gamedata =
        actions && actions.value && actions.value.data && actions.value.data.length > 0
          ? state.gameList.concat(actions.value.data)
          : state.gameList;
    
      // @ts-ignore
      let uniqueGame = [...new Map(gamedata.map((item: any) => [item["id"], item])).values()];
      console.log("UniqueGame", uniqueGame);
      var sortGame =uniqueGame.sort((a: any, b: any) => a.position - b.position);
      return {
        ...state,
        gameList: sortGame,
        msg: actions.value.msg,
        gameCount: actions.value.count,
        isLoading: false,
      };
    case types.AUTH.FAILURE_ADMIN_LOGIN:
      console.log("action,", actions);
      return {
        errMsg: actions?.error,
      };

    case types.MESSAGES.RESPONSE_SEASONLIST:
      return {
        ...state,
        seasonList: actions.value.data,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_SEASONDELETE:
      return {
        ...state,
        seasonList: actions.value.data,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_SEASONUPDATE:
      return {
        ...state,
        seasonList: actions.value.data,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_EPISODELIST:
      return {
        ...state,
        episodeList: actions.value.data,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_EPISODEUPDATE:
      return {
        ...state,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_EPISODEDELETE:
      return {
        ...state,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_ERRDELETEUSERHISTORY:
      return {
        ...state,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_DELETEUSERHISTORY:
      return {
        ...state,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_ERRDELETEUSERHISTORY1:
      return {
        ...state,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_ERRDELETEUSERHISTORY2:
      return {
        ...state,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_ERRDELETEUSERHISTORY3:
      return {
        ...state,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_USERLISTUPDATE:
      return {
        ...state,
        msg: actions.value.msg,
        isLoading: false,
      };

    case types.MESSAGES.RESPONSE_USERLISTDELETE:
      return {
        ...state,
        msg: actions.value.msg,
        isLoading: false,
      };
    case types.QUESTIONLIST.DELETE_MULTIPLEQUESTIONLIST:
      console.log("action", actions);
      return {
        ...state,
        questionList: state.questionList.filter((item: any) => !actions.data.includes(item.id)),
        isLoading: false,
      };
    case types.MESSAGES.RESPONSE_BATCHDELETE:
      console.log("action", actions);
      return {
        ...state,
        // questionList: state.questionList.filter((item: any) => !actions.data.includes(item.id)),
        isLoading: false,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_USERLISTAdd:
      return {
        ...state,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_EPISODECREATE:
      return {
        ...state,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_STACKCREATE:
      return {
        ...state,
        msg: actions.value.msg,
        isLoading: false,
      };
    case types.MESSAGES.RESPONSE_STACKUPDATE:
      return {
        ...state,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_STACKDELETE:
      return {
        ...state,
        msg: actions.value.msg,
      };

    case types.STACKLIST.REQUEST_FETCH_STACKLIST:
      return {
        ...state,
        isLoading: true,
        stackPage: actions?.data?.page ?? 1,
        stackSearchKeyword: actions?.data?.keyword ?? "",
      };

    case types.MESSAGES.RESPONSE_STACKLIST:
      var stackdata =
        actions && actions.value && actions.value.data && actions.value.data.length > 0
          ? actions.value.data
          : state.stackList;
      var sortStack = [...stackdata].sort((a: any, b: any) => a.id - b.id);
      // @ts-ignore
      let uniqueStack = [...new Map(sortStack.map((item: any) => [item["id"], item])).values()];
      return {
        ...state,
        stackList: uniqueStack,
        stackCount: actions.value.count,
        msg: actions.value.msg,
        isLoading: false,
      };

    case types.MESSAGES.ERROR_STACKLIST:
      return {
        ...state,
        stackList: [],
        stackCount: 0,
        isLoading: false,
      };
    case types.MESSAGES.RESPONSE_STACKFOUND:
      return {
        ...state,
        stackList: actions.value.data,
        stackCount: actions.value.count,
        msg: "Stack list found",
        isLoading: false,
      };
    case types.REFRESHDATA.MSG:
      return {
        ...state,
        isLoading: true,
        msg: "",
        errMsg: "",
      };
    case types.MESSAGES.RESPONSE_GAMECREATE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_QUESTIONCREATE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_QUESTIONUPDATE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_COUNT:
      return {
        ...state,
        TotalQuestion: actions.value.questionsCount,
        TotalUser: actions.value.usersCount,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_AppConfig:
      console.log("action", actions);
      return {
        ...state,
        isLoading: false,
        appConfig: actions.value.data,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_QUESTIONDELETE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_FETCHPRIZE:
      console.log("data", actions.value);
      return {
        ...state,
        isLoading: false,
        prizeList: actions.value.data,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_DELETEPRIZE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_CREATEPRIZE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_AVATARLIST_CREATED:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_AVATARLIST_DELETED:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_AVATARLIST:
      return {
        ...state,
        isLoading: false,
        avatarList: actions.data,
      };

    case types.MESSAGES.RESPONSE_WINNERLIST:
      return {
        ...state,
        isLoading: false,
        winnerlist: actions.value.data,
      };

    case types.MESSAGES.RESPONSE_WINNERLISTUPDATE:
      return {
        ...state,
        msg: actions.value.msg,
        isLoading: false,
      };

      case types.MESSAGES.RESPONSE_WINNERLISTADD:
        if(actions.value.data.length>0){
          return {
            ...state,
            msg: actions.value.msg,
            winnerAddApiDATA:actions.value,
            skipWeek:actions.value.skipWeek,
            skipDate:actions.value.skipDate
          };
        }
        else{
          return {
            ...state,
            msg: actions.value.msg,
            winnerAddApiDATA:actions.value,
          };
        }
        case types.MESSAGES.RESPONSE_WINNERLISTDELETE:
            return {
              ...state,
              msg: actions.value.msg,
            };

    case types.MESSAGES.RESPONSE_PAYMENTLIST:
      return {
        ...state,
        isLoading: false,
        paymentlist: actions.value.data,
      };

    case types.MESSAGES.RESPONSE_SCHOOLLIST:
      return {
        ...state,
        isLoading: false,
        schoollist: actions.value.data,
        schoollistdata:actions.value
      };

    case types.MESSAGES.RESPONSE_SCHOOLCREATE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_SCHOOLUPDATE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_SCHOOLMERGE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_SCHOOLDELETE:
      return {
        ...state,
        msg: actions.value.msg,
        isLoading: false,
      };

    case types.MESSAGES.RESPONSE_FAQCREATE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_FAQUPDATE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_FAQLIST:
      return {
        ...state,
        isLoading: false,
        FAQlist: actions.value,
      };

    case types.MESSAGES.RESPONSE_FAQDELETE:
      return {
        ...state,
        msg: actions.value.msg,
        isLoading: false,
      };

    case types.MESSAGES.RESPONSE_FEEDBACKLIST:
      return {
        ...state,
        isLoading: false,
        feedbacklist: actions.value.data,
      };

    case types.MESSAGES.RESPONSE_WINNERLISTPUBLISH:
      return {
        ...state,
        msg: actions.value.message,
        isLoading: false,
      };
      case types.MESSAGES.RESPONSE_WINNERPUBLISHMANAGE:
      return {
        ...state,
        msg: actions.value.msg,
        winnerPublishManage:actions.value.data,
        isLoading: false,
      };
  
  case types.MESSAGES.RESPONSE_MANAGE_PUBLISH_WINNERLIST:
      return {
        ...state,
        msg: actions.value.msg,
        ResponseManageWinnerPublish:actions.value,
        isLoading: false,
      };
    case types.MESSAGES.RESPONSE_STACK_QUESTION:
      return {
        ...state,
        stackQuestion: actions.value.data,
        totalQuestions: actions.value.totalQuestions,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_UPDATEPRIZE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_SEASONCREATE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_AppConfigCREATE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };

    case types.GAMELIST.DELETE_GAME:
      var id = actions && actions.value && actions.value.data ? actions.value.data : "";
      const filteredList = state.gameList.filter((item: any) => item.id !== id);
      console.log("filterlist", filteredList);
      return {
        ...state,
        gameList: filteredList,
      };
    case types.MESSAGES.RESPONSE_AppConfigUPDATE:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_UPDATEGAME:
      return {
        ...state,
        isLoading: false,
        msg: actions.value.msg,
      };

    case types.MESSAGES.ERRORLOGIN:
      console.log("Error");
      return {
        ...state,
        isLoading: false,
        errMsg: actions?.error,
      };

    case types.QUESTIONLIST.REQUEST_FETCH_QUESTIONLIST:
      console.log("Fetch Question list data", actions);
      return {
        ...state,
        questionFilters: actions?.data ?? null,
        isLoading: false,
        questionSearchKeyword: actions?.data?.keyword ?? "",
        questionPage: actions?.data?.page ?? 1,
      };

    case types.MESSAGES.RESPONSE_QUESTIONLIST:
      var questiondata =
        actions && actions.value && actions.value.data && actions.value.data.length > 0
          ? state.questionList.concat(actions.value.data)
          : state.questionList;
      var sortQuestion = [...questiondata].sort((a: any, b: any) => a.id - b.id);
      // @ts-ignore
      let uniqueQuestion = [
        // @ts-ignore
        ...new Map(sortQuestion.map((item: any) => [item["id"], item])).values(),
      ];
      return {
        ...state,
        questionList: uniqueQuestion,
        questionCount: actions.value.count,
        qCount: actions.value.questionCount,
        category: actions.value.subject,
        subCategory: actions.value.subCategory,
        isLoading: false,
      };

    case types.MESSAGES.ERROR_QUESTIONLIST:
      return {
        ...state,
        isLoading: false,
        questionList: [],
        questionCount: 0,
        qCount: 0,
      };

    case types.QUESTIONLIST.DELTEQUESTIONLSIT:
      return {
        ...state,
        // questionList: actions.value.data,
        msg: "",
        isLoading: true,
        userList: [],
        stackList: [],
      };
    case types.QUESTIONLIST.NULLQUESTIONLIST:
      return {
        ...state,
        // questionList: actions.value.data,
        msg: "",
        isLoading: true,
        questionList: [],
      };
    case types.MESSAGES.RESPONSE_CSVUPLOADED:
      return {
        ...state,
        msg: actions.value.msg,
        isLoading: false,
      };
    case types.MESSAGES.SEARCH_FOUND:
      return {
        ...state,
        userList:
          actions && actions.value && actions.value.data && actions.value.data.length > 0
            ? actions.value.data
            : [],
        isLoading: false,
        usermsg: "userlist found",
      };

    case types.QUESTIONLIST.REQUEST_FETCH_SINGLEQUESTION:
      const { offset } = actions.data;
      console.log("Questions Offset", offset, state.questionList);
      const question: any = state.questionList[offset];
      return {
        ...state,
        isLoading: false,
        msg: "data",
        QuestionObj: { ...question, index: offset },
      };

    case types.MESSAGES.RESPONSE_NOTICECREATE:
      return {
        ...state,
        msg: actions.value.msg,
      };
    case types.QUESTIONLIST.SINGLE:
      console.log("questions", actions);
      return {
        ...state,
        QuestionObj: actions.data,
      };
    case types.MESSAGES.RESPONSE_LINK:
      return {
        ...state,
        msg: actions.value.msg,
        link: actions.value.url,
      };

    case types.MESSAGES.QUESTION_LINK:
      return {
        ...state,
        msg: actions.value.msg,
        QuestionLink: actions.value.url,
      };

    case types.MESSAGES.USER_LINK:
      return {
        ...state,
        msg: actions.value.msg,
        UserLink: actions.value.url,
      };

    case types.MESSAGES.RESPONSE_NOTICEUPDATE:
      return {
        ...state,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_NOTICEDELETE:
      return {
        ...state,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_BANNERSCREATE:
      return {
        ...state,
        msg: actions.value.msg,
      };

    case types.MESSAGES.RESPONSE_BANNERSUPDATE:
      return {
        ...state,
        msg: actions.value.msg,
      };
    case types.MESSAGES.RESPONSE_BANNERSDELETE:
      return {
        ...state,
        msg: actions.value.msg,
      };

    case types.MESSAGES.DOWNLOAD_FEEDBACKLIST:
      return {
        ...state,
        msg: actions.value.msg,
        UserLink: actions.value.url,
      };

    case types.MESSAGES.RESPONSE_SCHOOLDOWNLOAD:
      return {
        ...state,
        msg: actions.value.msg,
        UserLink: actions.value.url,
      };

    case types.MESSAGES.RESPONSE_PAYMENTLISTDOWNLOAD:
      return {
        ...state,
        msg: actions.value.msg,
        UserLink: actions.value.url,
      };

    case types.MESSAGES.RESPONSE_ALLCOUNT:
      return {
        ...state,
        allCount: actions.value.data,
        isLoading: false,
      };

    case types.USERLIST.REQUEST_FETCH_USERLIST:
      return {
        ...state,
        isLoading: true,
        userListSearchKeyword: actions?.data?.keyword ?? "",
        userListPage: actions?.data?.page ?? 1,
      };

    case types.MESSAGES.RESPONSE_USERLIST:
      var data =
        actions && actions.value && actions.value.data && actions.value.data.length > 0
          ? state.userList.concat(actions.value.data)
          : state.userList;
      var sortData = [...data].sort((a: any, b: any) => a.id - b.id);
      // @ts-ignore
      let unique = [...new Map(sortData.map((item: any) => [item["id"], item])).values()];
      return {
        ...state,
        userList: unique,
        userCount: actions.value.count,
        isLoading: false,
        usermsg: "userlist found",
      };

    case types.MSG.CLEAR_MSG:
      return {
        ...state,
        msg: "",
      };

    case types.WEBSOCKET.WS_CONNECTED:
      return {
        ...state,
        WSCONNECTED: true,
      };

    case types.AUTH.REQUEST_ADMIN_LOGOUT:
      secureStorage.removeItem("isLoggedIn");
      secureStorage.removeItem("admin");
      secureStorage.removeItem("token");
      secureStorage.removeItem("rememberMe");
      return {
        ...state,
        admin: {},
        token: null,
        isLoggedIn: false,
      };

    case types.MESSAGES.RESPONSE_TOKEN_EXPIRED:
      secureStorage.removeItem("isLoggedIn");
      secureStorage.removeItem("admin");
      secureStorage.removeItem("token");
      secureStorage.removeItem("rememberMe");
      return {
        ...state,
        admin: {},
        token: null,
        isLoggedIn: false,
      };

    case types.AUTH.REQUEST_ADMIN_LOGIN:
      return {
        ...state,
        admin: {},
        token: null,
        isLoggedIn: false,
      };

    case types.MESSAGES.FAILURE:
      console.log("FAILURE", actions);
      return {
        ...state,
      };

    case types.MESSAGES.END_POINT_REQUEST:
      return {
        ...state,
        isLoading: false,
        msg: types.MESSAGES.WRONG,
      };

    default:
      return state;
  }
}
